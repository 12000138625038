const staticNodeActions = [
  {
    name: "None",
    description: "No action will be taken.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "MiscellaneousActions",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Summary",
    description: "Generate a simple summary of the data or text.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Summarization",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Overview",
    description: "Write an Overview as an expert.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Summarization",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Synopsis",
    description: "Write a Synopsis as an expert.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Summarization",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Abstract",
    description: "Craft an abstract summarizing the essential aspects.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Summarization",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Outline",
    description: "Provide an outline of the key themes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Summarization",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Review",
    description:
      "Write a review of the article, focusing on the main arguments. Use critical analysis.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Summarization",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Augment",
    description: "Augment the data with additional data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Augmentation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Enhance",
    description: "Add new features to enhance the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Augmentation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Enrich",
    description: "Enrich the data with rich information.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Augmentation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Grow",
    description: "Semanticaly grow data with natural information.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Augmentation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Expand",
    description: "Expand entities in data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Augmentation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Extend",
    description: "Extend relations in data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Augmentation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Cleaning",
    description: "Clean and preprocess the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Forecasting",
    description: "Predict future data points based on historical data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Classification",
    description: "Categorize data into predefined classes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Clustering",
    description: "Group data points into clusters based on similarity.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Anomalies",
    description: "Identify anomalies and unusual data points.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Split",
    description: "Split content into smaller parts by headings.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Split",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Split Steps",
    description: "Split content into smaller files by step 1, step 2, etc..",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Split",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Recommendations",
    description: "Generate recommendations based on data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SystemsAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Translation",
    description: "Translate text to a specified language.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Sentiment",
    description: "Determine the sentiment of the text.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Keywords",
    description: "Extract key terms from the text.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "NER",
    description: "Identify and classify entities in the text.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TextGen",
    description: "Generate new text based on a given prompt.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Normalization",
    description: "Normalize the data to a standard range.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DimReduction",
    description: "Reduce the number of features in the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Integration",
    description: "Combine data from multiple sources.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Validation",
    description: "Validate the data for better analysis.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OutlierRemoval",
    description: "Remove outliers from the dataset.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "FeatureSelection",
    description: "Select the most important features from the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PCA",
    description: "Perform PCA to reduce dimensionality.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TimeSeries",
    description: "Analyze time-dependent data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Correlation",
    description: "Analyze the correlation between variables.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Regression",
    description: "Perform regression analysis on the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Tokenization",
    description: "Tokenize text into words or sentences.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "POSTagging",
    description: "Tag parts of speech in text.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TopicModeling",
    description: "Identify topics in a collection of documents.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DependencyParsing",
    description: "Analyze grammatical structure of sentences.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TextSimilarity",
    description: "Calculate similarity between text segments.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "MachineTranslation",
    description: "Translate text using machine translation models.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "QASystem",
    description: "Provide answers to questions based on provided text.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "LangDetect",
    description: "Detect the language of the given text.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SpamDetection",
    description: "Identify spam content in text.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TextCompletion",
    description: "Complete a given text prompt.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "GrammarCheck",
    description: "Check and correct grammar in text.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PatternRecognition",
    description: "Identify patterns in data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DataAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TextToSQL",
    description: "Convert natural language questions to SQL queries.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TextAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Visualization",
    description: "Create visual representations of the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Visualization",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "Joke",
    description: "Tell a joke.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "MiscellaneousActions",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "KnowledgeGraphExtraction",
    description: "Extract the entities and relations in the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "KnowledgeGraph",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "KnowledgeGraphLinking",
    description: "Link the entities and relations in the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "KnowledgeGraph",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "KnowledgeGraphResolution",
    description: "Resolve the entities and relations in the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "KnowledgeGraph",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "KnowledgeGraphOptimization",
    description: "Optimize the entities and relations in the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "KnowledgeGraph",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "KnowledgeGraphValidation",
    description: "Validate the entities and relations in the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "KnowledgeGraph",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "KnowledgeGraphDisambiguation",
    description: "Disambiguate the entities and relations in the data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "KnowledgeGraph",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PortfolioDesign",
    description:
      "Design investment portfolios tailored to client needs, including asset selection, diversification, and strategic allocation.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "PortfolioManagement",
    description:
      "Manage investment portfolios by monitoring performance, rebalancing assets, and ensuring alignment with investment goals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "PortfolioAllocation",
    description:
      "Determine the optimal allocation of assets within an investment portfolio to maximize returns and minimize risks.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "AssetClassificationSystem",
    description:
      "Classify assets within a portfolio to ensure diversification, strategic alignment, and effective risk management.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "RiskClassification",
    description:
      "Classify investment risks to inform portfolio management strategies and ensure risk tolerance alignment.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "RewardClassification",
    description:
      "Classify potential rewards within an investment portfolio to balance risk and maximize returns.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "GrowthInvestingStrategy",
    description:
      "Implement growth investing strategies focused on companies with strong potential for earnings growth.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "ValueInvestingStrategy",
    description:
      "Implement value investing strategies focused on undervalued stocks with strong fundamentals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "IncomeInvestingStrategy",
    description:
      "Implement income investing strategies focused on generating regular income through dividends and interest.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "DiversificationStrategy",
    description:
      "Implement diversification strategies to spread risk across different asset classes and sectors.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "RebalancingStrategy",
    description:
      "Implement rebalancing strategies to maintain the desired asset allocation and manage portfolio risk.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "DefensiveInvestingStrategy",
    description:
      "Implement defensive investing strategies to protect against market downturns and reduce volatility.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "AggressiveInvestingStrategy",
    description:
      "Implement aggressive investing strategies to achieve high returns through high-risk investments.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "AssetAllocationStrategy",
    description:
      "Develop and implement asset allocation strategies to balance risk and return across different asset classes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "TacticalAssetAllocation",
    description:
      "Implement tactical asset allocation strategies to adjust portfolio allocations based on short-term market conditions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicAssetAllocation",
    description:
      "Implement strategic asset allocation strategies to maintain a consistent allocation aligned with long-term goals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "RiskAdjustedReturnAnalysis",
    description:
      "Analyze and report on risk-adjusted returns, evaluating the performance of investments relative to their risk.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "PortfolioStressTesting",
    description:
      "Conduct portfolio stress testing to evaluate how the portfolio performs under adverse market conditions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "PortfolioOptimization",
    description:
      "Optimize investment portfolios to maximize returns for a given level of risk, using techniques such as mean-variance optimization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "PeterLynchPlaybookReporting",
    description:
      "Apply Peter Lynch's investment principles, such as 'invest in what you know' and finding 'tenbaggers,' to portfolio management.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "PortfolioManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "SWOTAnalysis",
    description:
      "Conduct and report on SWOT analysis to identify internal and external factors affecting the business.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PESTLEAnalysis",
    description:
      "Conduct and report on PESTLE analysis to evaluate external factors impacting the business environment.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CompetitorAnalysis",
    description:
      "Analyze and report on competitors' strategies, market positioning, and potential threats to the business.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "MarketEntryStrategyAnalysis",
    description:
      "Analyze and report on market entry strategies, including market research, entry barriers, and potential opportunities.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "BusinessModelAnalysis",
    description:
      "Analyze and report on business models, evaluating their effectiveness and sustainability.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ValueChainAnalysis",
    description:
      "Conduct and report on value chain analysis to identify areas for improving operational efficiency and value creation.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ScenarioPlanning",
    description:
      "Conduct and report on scenario planning to anticipate and prepare for various future business scenarios.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PortfolioAnalysis",
    description:
      "Analyze and report on business portfolios, evaluating their performance and alignment with strategic goals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "RiskManagementReporting",
    description:
      "Identify, analyze, and report on strategic risks, including risk assessments and mitigation strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "BlueOceanStrategyAnalysis",
    description:
      "Analyze and report on Blue Ocean strategies to identify and create new market spaces with minimal competition.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CoreCompetencyAnalysis",
    description:
      "Identify and report on core competencies that provide the business with a competitive advantage in the market.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicFitAnalysis",
    description:
      "Analyze and report on the strategic fit between initiatives and organizational goals, ensuring alignment and effectiveness.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicOptionAnalysis",
    description:
      "Evaluate and report on various strategic options, analyzing their potential impact and feasibility.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CompetitiveAdvantageAnalysis",
    description:
      "Analyze and report on the sources of competitive advantage and their sustainability over time.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "InnovationStrategyReporting",
    description:
      "Analyze and report on innovation strategies, evaluating their impact on business growth and competitiveness.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicAllianceAnalysis",
    description:
      "Analyze and report on strategic alliances, including their formation, performance, and impact on business objectives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OrganizationalCapabilityAnalysis",
    description:
      "Analyze and report on organizational capabilities needed to achieve strategic objectives and drive business success.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicResourceAllocation",
    description:
      "Analyze and report on the allocation of resources to strategic initiatives, ensuring optimal use of assets and capabilities.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "LongTermVisionPlanning",
    description:
      "Develop and report on the long-term vision and goals of the organization, outlining the strategic roadmap for future success.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicChangeManagement",
    description:
      "Analyze and report on the management of strategic change, including change readiness, implementation, and outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "GrowthStrategyAnalysis",
    description:
      "Analyze and report on growth strategies, evaluating their potential outcomes and impact on business expansion.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "MarketPositioningAnalysis",
    description:
      "Analyze and report on market positioning strategies, assessing their effectiveness in achieving competitive advantage.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicPerformanceIndicators",
    description:
      "Define and report on strategic performance indicators, measuring the success of strategic initiatives and overall business performance.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "DisruptiveInnovationAnalysis",
    description:
      "Analyze and report on disruptive innovations, assessing their impact on market dynamics and business strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "MergersAcquisitionsStrategy",
    description:
      "Develop and report on strategies for mergers and acquisitions, including target identification, due diligence, and integration plans.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicBenchmarking",
    description:
      "Compare strategic initiatives against industry benchmarks to identify best practices and areas for improvement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicScenarioModelling",
    description:
      "Create and report on strategic scenarios, using modelling techniques to guide decision-making and strategic planning.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "BalancedScorecardReporting",
    description:
      "Implement and report on the Balanced Scorecard framework, using it to manage and measure strategic performance.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessAnalysis",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "IncomeStatementReporting",
    description:
      "Prepare and analyze income statements, including revenues, expenses, and net income.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "BalanceSheetReporting",
    description:
      "Prepare and analyze balance sheets, including assets, liabilities, and equity.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "CashFlowStatementReporting",
    description:
      "Prepare and analyze cash flow statements, including operating, investing, and financing activities.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialRatioAnalysis",
    description:
      "Conduct financial ratio analysis, including liquidity, profitability, and solvency ratios.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "InvestmentAnalysisReporting",
    description:
      "Analyze and report on investment performance, including return on investment (ROI), risk assessments, and portfolio management.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialRiskReporting",
    description:
      "Analyze and report on financial risks, including market risk, credit risk, and liquidity risk.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "TaxComplianceReporting",
    description:
      "Ensure and report on tax compliance, including preparation of tax returns, compliance with tax regulations, and tax planning strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "AuditingAndAssuranceReporting",
    description:
      "Conduct and report on financial audits and assurance services, ensuring accuracy and compliance with financial reporting standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialPerformanceDashboard",
    description:
      "Develop and manage financial performance dashboards, providing real-time insights into key financial metrics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "DividendAnalysisReporting",
    description:
      "Analyze and report on dividend policies and payments, including dividend yield, payout ratios, and impact on shareholder value.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "WorkingCapitalAnalysisReporting",
    description:
      "Analyze and report on working capital management, including current assets and liabilities, liquidity ratios, and cash conversion cycles.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialRegulatoryComplianceReporting",
    description:
      "Ensure and report on compliance with financial regulations, including adherence to industry standards, legal requirements, and regulatory guidelines.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "CashFlowAnalysisReporting",
    description:
      "Analyze and report on cash flow, including cash flow statements, cash flow projections, and liquidity analysis.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialHealthCheckReporting",
    description:
      "Conduct and report on financial health checks, evaluating the overall financial health and performance of the business.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "CreditRiskAnalysisReporting",
    description:
      "Analyze and report on credit risk, including assessments of creditworthiness, credit exposure, and risk mitigation strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialStrategicPlanningReporting",
    description:
      "Report on financial strategic planning processes and outcomes, including strategic goals, action plans, and performance tracking.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialScenarioAnalysis",
    description:
      "Conduct and report on financial scenario analysis, evaluating potential financial outcomes under different scenarios.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialTechnologyAnalysis",
    description:
      "Analyze and report on the impact of financial technology (FinTech) on business operations, including digital payments, blockchain, and AI-driven financial services.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Financial",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "ProjectStatusReporting",
    description:
      "Analyze and report on project status and progress, including milestones, deliverables, and risks.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProjectManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "ProjectPerformanceReporting",
    description:
      "Analyze and report on project performance and outcomes, evaluating success criteria, KPIs, and lessons learned. Suggest improvements and corrective actions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProjectManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "ProjectRiskReporting",
    description:
      "Identify and report on project risks, including risk assessments, mitigation strategies, and monitoring.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProjectManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "ProjectCostReporting",
    description:
      "Analyze and report on project costs and budgets, tracking expenses, variances, and cost-saving opportunities.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProjectManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "ProjectScheduleReporting",
    description:
      "Analyze and report on project schedules and timelines, identifying critical path activities and potential delays.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProjectManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "ProjectResourceReporting",
    description:
      "Analyze and report on project resources and allocations, including labor, equipment, and materials.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProjectManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "ProjectQualityReporting",
    description:
      "Analyze and report on project quality and performance, including quality metrics, inspections, and testing results.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProjectManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "ProjectStakeholderReporting",
    description:
      "Manage and report on project stakeholder relationships, including communication plans, engagement strategies, and conflict resolution.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProjectManagement",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialStatementReporting",
    description:
      "Prepare and analyze financial statements, including balance sheets, income statements, and cash flow statements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "BudgetVarianceReporting",
    description:
      "Analyze and report on budget variances, identifying differences between actual and budgeted figures and their causes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "KPIReporting",
    description:
      "Define and report on key performance indicators (KPIs) to measure and track business performance.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "SalesPerformanceReporting",
    description:
      "Analyze and report on sales performance, including revenue, sales growth, and sales targets achievement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "OperationalPerformanceReporting",
    description:
      "Analyze and report on operational performance metrics, including efficiency, productivity, and process improvements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "MarketTrendReporting",
    description:
      "Analyze and report on market trends, including industry developments, market conditions, and competitive landscape.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerSatisfactionReporting",
    description:
      "Analyze and report on customer satisfaction metrics, including survey results, Net Promoter Score (NPS), and feedback analysis.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "RiskAssessmentReporting",
    description:
      "Identify and report on business risks, including risk assessments, risk mitigation strategies, and monitoring.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "FinancialForecastingReporting",
    description:
      "Conduct financial forecasting and report on future financial performance, including revenue projections and expense estimates.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProfitabilityAnalysisReporting",
    description:
      "Analyze and report on business profitability, including profit margins, cost structures, and profitability drivers.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicPlanningReporting",
    description:
      "Report on strategic planning processes and outcomes, including strategic goals, action plans, and performance tracking.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "RegulatoryComplianceReporting",
    description:
      "Ensure and report on regulatory compliance, including adherence to industry standards, legal requirements, and regulatory guidelines.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "BusinessProjectPerformanceReporting",
    description:
      "Analyze and report on project performance, including project timelines, budget adherence, and project outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeePerformanceReporting",
    description:
      "Analyze and report on employee performance, including performance appraisals, productivity metrics, and development plans.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "InventoryManagementReporting",
    description:
      "Analyze and report on inventory management practices, including inventory turnover, stock levels, and supply chain efficiency.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CostAnalysisReporting",
    description:
      "Analyze and report on business costs, including cost structures, cost-saving opportunities, and cost management strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "MergersAcquisitionsReporting",
    description:
      "Report on mergers and acquisitions activities, including target identification, due diligence, and post-merger integration.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "BusinessPerformanceDashboard",
    description:
      "Develop and manage business performance dashboards, providing real-time insights into key performance indicators.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "MarketResearchReporting",
    description:
      "Conduct and report on market research studies, including consumer behavior analysis and market trends.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "BusinessHealthCheckReporting",
    description:
      "Conduct and report on business health checks, evaluating the overall health and performance of the business.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerAnalyticsReporting",
    description:
      "Analyze and report on customer data and behavior, including purchasing patterns, customer segmentation, and lifetime value.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductPerformanceReporting",
    description:
      "Analyze and report on product performance, including sales data, market penetration, and product lifecycle analysis.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "BusinessIntelligenceReporting",
    description:
      "Develop and report on business intelligence initiatives, providing insights through data analysis and visualization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "BusinessContinuityPlanningReporting",
    description:
      "Develop and report on business continuity plans, ensuring preparedness for disruptions and continuity of operations.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "BusinessProcessImprovementReporting",
    description:
      "Analyze and report on business process improvement initiatives, identifying opportunities for efficiency gains and cost reductions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CorporateSocialResponsibilityReporting",
    description:
      "Report on corporate social responsibility (CSR) initiatives, including sustainability efforts, community engagement, and ethical practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "BusinessReporting",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "RDInvestmentReporting",
    description:
      "Generate detailed reports on research and development investments, including financial allocations and outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ResearchAndDevelopment",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "InnovationPipelineReporting",
    description:
      "Report on the status and progress of innovation projects, highlighting key milestones and achievements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Innovation",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "PatentIPReporting",
    description:
      "Analyze and report on patents and intellectual property developments within the organization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Innovation",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "RDPerformanceMetricsReporting",
    description:
      "Evaluate and report on key performance metrics for research and development activities.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ResearchAndDevelopment",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "InnovationImpactAnalysis",
    description:
      "Analyze and report on the impact of innovation initiatives on business performance and market position.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Innovation",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "RDResourceAllocationReporting",
    description:
      "Report on the allocation of resources, including budget and personnel, to various RD projects.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ResearchAndDevelopment",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "InnovationBenchmarking",
    description:
      "Benchmark innovation metrics against industry standards to identify areas for improvement and best practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Innovation",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "RDProjectRiskAnalysis",
    description:
      "Identify and report on risks associated with research and development projects, including mitigation strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ResearchAndDevelopment",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "TechnologyTrendAnalysis",
    description:
      "Analyze and report on emerging technology trends and their potential impact on the organization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Innovation",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "RDProductDevelopmentCycleReporting",
    description:
      "Report on the stages of the product development cycle within RD, highlighting key progress and challenges.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ResearchAndDevelopment",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "InnovationCollaborationReporting",
    description:
      "Analyze and report on collaboration efforts, including partnerships and teamwork, in innovation projects.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Innovation",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "RDExpenditureTracking",
    description:
      "Track and report on research and development expenditures, ensuring budget compliance and financial efficiency.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ResearchAndDevelopment",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "InnovationOutcomeMeasurement",
    description:
      "Measure and report on the outcomes and impact of innovation initiatives, including market adoption and ROI.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Innovation",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "RDRegulatoryComplianceReporting",
    description:
      "Ensure and report on regulatory compliance in research and development activities, including adherence to standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ResearchAndDevelopment",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "InnovationCultureAssessment",
    description:
      "Assess and report on the innovation culture within the organization, including employee engagement and support for innovation.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Innovation",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ITInfrastructureReporting",
    description:
      "Generate reports on the performance and status of IT infrastructure, including servers, networks, and data centers.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DigitalTransformationProgressReporting",
    description:
      "Analyze and report on the progress and impact of digital transformation initiatives, including key milestones and outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CybersecurityPostureReporting",
    description:
      "Generate reports on the organization's cybersecurity posture, including threat assessments and mitigation strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SoftwareDevelopmentMetricsReporting",
    description:
      "Analyze and report on software development metrics, including code quality, deployment frequency, and development speed.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CloudAdoptionReporting",
    description:
      "Generate reports on cloud adoption, including migration progress, cloud performance, and cost efficiency.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ITServiceManagementReporting",
    description:
      "Analyze and report on IT service management practices, including incident response, service availability, and customer satisfaction.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "InformationTechnology",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DigitalInnovationReporting",
    description:
      "Analyze and report on digital innovation initiatives, including new technology adoption and digital product development.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ITComplianceReporting",
    description:
      "Ensure and report on compliance with IT policies, standards, and regulatory requirements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "InformationTechnology",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DataAnalyticsReporting",
    description:
      "Analyze and report on data analytics initiatives, including data quality, analytics capabilities, and business impact.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TransformationDigitalCustomerExperienceReporting",
    description:
      "Generate reports on the digital customer experience, including website performance, mobile app usability, and customer feedback.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ITCostManagementReporting",
    description:
      "Analyze and report on IT cost management, including budgeting, cost-saving initiatives, and financial performance.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "InformationTechnology",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ITProjectManagementReporting",
    description:
      "Generate reports on IT project management metrics, including project timelines, resource allocation, and project outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "InformationTechnology",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EnterpriseArchitectureReporting",
    description:
      "Analyze and report on enterprise architecture frameworks and practices, including system integration and IT alignment with business goals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DigitalSkillsetReporting",
    description:
      "Generate reports on the digital skillset of the workforce, including training programs and skill development initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ITPerformanceMetricsReporting",
    description:
      "Generate detailed reports on key IT performance metrics, including system performance, downtime, and user satisfaction.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "InformationTechnology",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DigitalTransformationROIReporting",
    description:
      "Measure and report on the return on investment (ROI) of digital transformation initiatives, including cost savings and business impact.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DigitalTransformation",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ITSecurityIncidentReporting",
    description:
      "Generate reports on IT security incidents, including details of the incidents, responses taken, and lessons learned.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "InformationTechnology",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ITGovernanceReporting",
    description:
      "Analyze and report on IT governance practices, including policies, procedures, and compliance with governance standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "InformationTechnology",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ITInnovationMetricsReporting",
    description:
      "Generate reports on IT innovation metrics, including adoption of new technologies, innovation projects, and impact on business processes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "InformationTechnology",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CampaignPerformanceReporting",
    description:
      "Generate reports on the performance of marketing campaigns, including reach, engagement, and conversion rates.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "SalesPipelineAnalysis",
    description:
      "Analyze and report on the sales pipeline, including stages, conversion rates, and forecast accuracy.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sales",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerAcquisitionCostReporting",
    description:
      "Generate reports on customer acquisition costs, including cost per lead, cost per acquisition, and overall campaign efficiency.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerLifetimeValueReporting",
    description:
      "Analyze and report on customer lifetime value, including customer retention rates and long-term profitability.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "MarketSegmentationReporting",
    description:
      "Generate reports on market segmentation, identifying key target audiences and their characteristics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "DigitalMarketingMetricsReporting",
    description:
      "Analyze and report on digital marketing metrics, including website traffic, SEO performance, and social media engagement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "LeadGenerationReporting",
    description:
      "Analyze and report on lead generation efforts, including lead sources, conversion rates, and campaign effectiveness.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "BrandAwarenessReporting",
    description:
      "Generate reports on brand awareness, including brand perception surveys and social media sentiment analysis.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerEngagementReporting",
    description:
      "Analyze and report on customer engagement metrics, including interaction rates, feedback, and loyalty programs.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CompetitorAnalysisReporting",
    description:
      "Generate reports on competitor analysis, including competitive strategies, market position, and SWOT analysis.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "SalesForecastingReporting",
    description:
      "Forecast and report on future sales performance, including trend analysis and predictive modeling.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sales",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerRetentionReporting",
    description:
      "Analyze and report on customer retention strategies, including churn rates, loyalty programs, and customer feedback.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "MarketingROIReporting",
    description:
      "Measure and report on the return on investment (ROI) of marketing activities, including cost-benefit analysis.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "SalesConversionRateReporting",
    description:
      "Analyze and report on sales conversion rates, identifying factors that influence successful sales conversions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sales",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "MarketingChannelPerformanceReporting",
    description:
      "Generate reports on the performance of various marketing channels, including email, social media, and direct marketing.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Marketing",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "SalesTerritoryPerformanceReporting",
    description:
      "Analyze and report on the performance of sales territories, including territory management and sales growth.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sales",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "SalesIncentiveProgramsReporting",
    description:
      "Analyze and report on the effectiveness of sales incentive programs, including impact on sales performance and employee motivation.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sales",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "EnvironmentalImpactReporting",
    description:
      "Generate detailed reports on the environmental impact of corporate activities, including emissions, waste, and resource usage.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SocialResponsibilityReporting",
    description:
      "Analyze and report on social responsibility initiatives, including community engagement and social impact metrics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "GovernanceReporting",
    description:
      "Generate reports on corporate governance practices, including board composition, governance policies, and compliance with regulations.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SustainabilityMetricsReporting",
    description:
      "Analyze and report on sustainability metrics, including key performance indicators related to environmental, social, and governance (ESG) criteria.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "HumanRightsReporting",
    description:
      "Generate reports on human rights practices, including compliance with international standards and initiatives to protect human rights.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplyChainSustainabilityReporting",
    description:
      "Analyze and report on sustainability practices within the supply chain, including supplier compliance and sustainable sourcing.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CarbonFootprintReporting",
    description:
      "Generate detailed reports on the carbon footprint of corporate activities, including direct and indirect emissions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DiversityInclusionReporting",
    description:
      "Analyze and report on diversity and inclusion practices, including workforce diversity metrics and inclusion initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CommunityEngagementReporting",
    description:
      "Generate reports on community engagement initiatives, including impact assessments and community feedback.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EthicalBusinessPracticesReporting",
    description:
      "Analyze and report on ethical business practices, including anti-corruption measures and compliance with ethical standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ESGPerformanceReporting",
    description:
      "Generate reports on ESG performance, including detailed assessments of environmental, social, and governance criteria.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ClimateRiskReporting",
    description:
      "Analyze and report on climate-related risks, including risk assessments and mitigation strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ResourceEfficiencyReporting",
    description:
      "Generate reports on resource efficiency, including water, energy, and material usage.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "WasteManagementReporting",
    description:
      "Analyze and report on waste management practices, including waste reduction, recycling, and disposal methods.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "WaterUsageReporting",
    description:
      "Generate reports on water usage and conservation efforts, including usage metrics and water-saving initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "BiodiversityImpactReporting",
    description:
      "Analyze and report on the impact of corporate activities on biodiversity, including conservation efforts and habitat protection.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SustainableProductReporting",
    description:
      "Generate reports on the sustainability of products and services, including lifecycle assessments and sustainable design practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "RenewableEnergyUsageReporting",
    description:
      "Generate reports on the usage of renewable energy sources, including metrics on energy generation and consumption.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeWellbeingReporting",
    description:
      "Analyze and report on employee wellbeing initiatives, including health programs, work-life balance, and employee satisfaction.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "GreenBuildingCertificationReporting",
    description:
      "Generate reports on green building certifications, including LEED, BREEAM, and other certification programs.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "AntiCorruptionReporting",
    description:
      "Analyze and report on anti-corruption practices, including measures to prevent, detect, and address corruption.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CSRInitiativesReporting",
    description:
      "Generate reports on CSR initiatives, including program effectiveness, impact assessments, and stakeholder engagement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SustainableDevelopmentGoalsReporting",
    description:
      "Analyze and report on the alignment of corporate activities with the UN Sustainable Development Goals (SDGs), including progress and impact.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CircularEconomyReporting",
    description:
      "Generate reports on circular economy practices, including resource reuse, recycling, and waste minimization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SustainabilityTrainingReporting",
    description:
      "Analyze and report on the effectiveness of sustainability training programs, including participation and outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplyChainTransparencyReporting",
    description:
      "Generate reports on supply chain transparency, including traceability of materials and compliance with ethical sourcing standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CSRDReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "BookWriter",
    description: "Write a book. Performs a series of steps to write a book.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Writer",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "GoodVsBad",
    description:
      "Debate good vs. bad, evaluate arguments, and make a decision.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Writer",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "GoodVsEvil",
    description:
      "Debate good vs. bad, evaluate arguments, and make a decision.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Writer",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "AngelVsDevil",
    description:
      "Debate angel vs. devil, evaluate arguments, and make a decision.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Writer",
    sc: 3,
    r: 0,
    wh: "",
  },
  {
    name: "XTweet",
    description: "Write a tweet.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "XShort",
    description:
      "Write a short tweet. Provide links to be included in the tweet.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "XLong",
    description: "Write a long tweet.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "XFunny",
    description: "Write a funny tweet.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "XSad",
    description: "Write a sad tweet.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "FacebookPost",
    description:
      "Write a facebook post. Provide links to be included in the post.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "FacebookPostLong",
    description:
      "Write a long facebook post. Provide links to be included in the post.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "FacebookPostFunny",
    description:
      "Write a funny facebook post. Provide links to be included in the post.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "FacebookPostSad",
    description:
      "Write a sad facebook post. Provide links to be included in the post.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "RedditPost",
    description:
      "Write a reddit post. Provide links to be included in the post.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "InstagramPost",
    description:
      "Write an instagram post. Provide links to be included in the post.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "LinkedInPost",
    description:
      "Write a linkedin post. Provide links to be included in the post.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SocialMedia",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TimeBillionaire",
    description:
      "Time Billionaire: Value the time you have as it's a precious asset",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 1,
    r: 0,
    wh: "",
  },
  {
    name: "TheFeynmanTechnique",
    description: "The Feynman Technique: To learn anything, follow these steps",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "TheParadoxOfPersuasion",
    description:
      "The Paradox of Persuasion: Persuasion is an art that requires subtlety rather than force",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "TheFiveTypesOfWealth",
    description: "The 5 Types of Wealth: Wealth isn't just financial",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "IntellectualSparringPartners",
    description:
      "Intellectual Sparring Partners: Engage with people who challenge your ideas",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 6,
    r: 0,
    wh: "",
  },
  {
    name: "TheParadoxOfEffort",
    description:
      "The Paradox of Effort: To make something appear effortless, a significant amount of work is usually involved",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "HitchensRazor",
    description:
      "Hitchens' Razor: Anything asserted without evidence can be dismissed without evidence",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 10,
    r: 0,
    wh: "",
  },
  {
    name: "SunkCostFallacy",
    description:
      "Sunk Cost Fallacy: Don't continue with something just because you've already invested in it",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 8,
    r: 0,
    wh: "",
  },
  {
    name: "TheOptimistRazor",
    description: "The Optimist Razor: Spend time with optimists",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "HeavensRewardFallacy",
    description:
      "Heaven's Reward Fallacy: Hard work doesn't always lead to praise or recognition",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "LossAversion",
    description:
      "Loss Aversion: People fear losing more than they value gaining",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "UseItOrLoseIt",
    description:
      "Use-It-Or-Lose-It: Work expands to fill the time available for it",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 9,
    r: 0,
    wh: "",
  },
  {
    name: "SpotlightEffect",
    description: "Spotlight Effect: We overestimate how much others notice us",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "NaïveRealism",
    description:
      "Naïve Realism: We believe our perception of the world is the only accurate one",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Conceptualism",
    sc: 6,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfSocrates",
    description:
      "Socrates is known for his belief that questioning is the only path to true knowledge, encouraging deep self-reflection and challenging assumptions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 12,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfPlato",
    description:
      "Plato emphasized the existence of an ideal world of forms, where true knowledge resides, advocating for the philosopher-king to rule based on wisdom.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 12,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfAristotle",
    description:
      "Aristotle was a polymath who believed in the importance of empirical observation and logical reasoning to understand the natural world.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 10,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfKant",
    description:
      "Kant developed the concept of the categorical imperative, advocating that actions should be taken based on whether they can be universally applied.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 12,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfNietzsche",
    description:
      "Nietzsche questioned the foundations of Christianity and traditional morality, proposing that individuals should create their own values.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 10,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfLocke",
    description:
      "Locke is known for his theories on natural rights, the importance of property, and the idea that governments should operate with the consent of the governed.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 10,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfDescartes",
    description:
      "Descartes is known for his emphasis on doubt as a method of inquiry, leading to his famous conclusion 'I think, therefore I am'.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 10,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfHume",
    description:
      "Hume questioned the certainty of knowledge derived from experience, emphasizing the limits of human understanding.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 9,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfSartre",
    description:
      "Sartre advocated for the idea that existence precedes essence, meaning that individuals are free to create their own meaning in an indifferent universe.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 9,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfConfucius",
    description:
      "Confucius emphasized the importance of moral integrity, respect for tradition, and the role of family in achieving a harmonious society.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 12,
    r: 0,
    wh: "",
  },
  {
    name: "ViewOfSunTzu",
    description:
      "Sun Tzu is known for his treatise 'The Art of War', emphasizing the importance of strategy, leadership, and understanding the nature of conflict.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Philosophy",
    sc: 21,
    r: 0,
    wh: "",
  },
  {
    name: "GDPAnalysis",
    description:
      "Analyze and report on GDP trends, including contributing factors, economic growth rates, and sectoral contributions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "InflationAnalysis",
    description:
      "Analyze and report on inflation rates, including causes, trends, and impacts on purchasing power and cost of living.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "UnemploymentRateAnalysis",
    description:
      "Analyze and report on unemployment rates, labor market trends, and the implications for economic policy and workforce development.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "FiscalPolicyAnalysis",
    description:
      "Analyze and report on fiscal policy measures, including government spending, taxation, and their effects on the economy.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "MonetaryPolicyAnalysis",
    description:
      "Analyze and report on monetary policy, including interest rates, money supply, and their impact on inflation and economic growth.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TradeBalanceAnalysis",
    description:
      "Analyze and report on trade balance, including exports, imports, trade deficits, and their impact on the economy.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ConsumerConfidenceAnalysis",
    description:
      "Analyze and report on consumer confidence indices, including trends, influencing factors, and implications for economic activity.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "BusinessCycleAnalysis",
    description:
      "Analyze and report on business cycles, including phases of expansion, peak, contraction, and trough, and their economic impact.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CurrencyExchangeRateAnalysis",
    description:
      "Analyze and report on currency exchange rates, including trends, influencing factors, and their impact on international trade and investment.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EconomicIndicatorForecasting",
    description:
      "Forecast and report on key economic indicators, including GDP growth, inflation, unemployment rates, and trade balances.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "HousingMarketAnalysis",
    description:
      "Analyze and report on housing market trends, including prices, supply and demand dynamics, and their economic impact.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "IncomeInequalityAnalysis",
    description:
      "Analyze and report on income inequality, including trends, contributing factors, and implications for social and economic policy.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PublicDebtAnalysis",
    description:
      "Analyze and report on public debt, including debt levels, sustainability, and their impact on fiscal policy and economic stability.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SectoralEconomicAnalysis",
    description:
      "Analyze and report on the economic performance of various sectors, including manufacturing, services, and agriculture.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmploymentTrendsAnalysis",
    description:
      "Analyze and report on employment trends, including job creation, sectoral employment shifts, and workforce participation rates.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ForeignDirectInvestmentAnalysis",
    description:
      "Analyze and report on foreign direct investment (FDI) trends, including inflows, outflows, and their economic impact.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "InflationExpectationsAnalysis",
    description:
      "Analyze and report on inflation expectations, including survey data, market-based measures, and their impact on economic behavior.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EconomicResilienceAnalysis",
    description:
      "Analyze and report on economic resilience, including the ability of economies to adapt to and recover from shocks and disruptions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ConsumerSpendingAnalysis",
    description:
      "Analyze and report on consumer spending trends, including expenditure patterns, influencing factors, and their economic impact.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "InterestRateAnalysis",
    description:
      "Analyze and report on interest rate trends, including central bank policies, market reactions, and their impact on economic activity.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "FiscalDeficitAnalysis",
    description:
      "Analyze and report on fiscal deficits, including causes, trends, and their impact on economic stability and growth.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DemographicEconomicAnalysis",
    description:
      "Analyze and report on the economic impact of demographic trends, including aging populations, workforce demographics, and population growth.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ProductivityGrowthAnalysis",
    description:
      "Analyze and report on productivity growth, including contributing factors, trends, and their impact on economic performance.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "GlobalEconomicOutlookReporting",
    description:
      "Analyze and report on global economic outlooks, including regional forecasts, global growth trends, and economic risks.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Economics",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PorterFiveForcesAnalysis",
    description:
      "Conduct and report on Porter’s Five Forces analysis to evaluate competitive pressures within the industry.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "StrategicAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "InnovationStrategyAnalysis",
    description:
      "Analyze and report on innovation strategies, evaluating their impact on business growth and competitiveness.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "StrategicAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicRiskManagement",
    description:
      "Identify, analyze, and report on strategic risks, including risk assessments and mitigation strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "StrategicAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "BusinessModelInnovationAnalysis",
    description:
      "Analyze and report on business model innovation, evaluating its impact on market positioning and competitive advantage.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "StrategicAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EcosystemMapping",
    description:
      "Map and report on the business ecosystem, including key stakeholders, relationships, and their impact on strategic objectives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "StrategicAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CompetitiveStrategyAnalysis",
    description:
      "Analyze and report on competitive strategies, evaluating their effectiveness and impact on market dynamics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "StrategicAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicRoadmapDevelopment",
    description:
      "Develop and report on strategic roadmaps, outlining the steps and milestones for achieving long-term business goals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "StrategicAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StrategicGoalsAlignment",
    description:
      "Align strategic goals with organizational capabilities and resources, ensuring coherence and effectiveness in strategy implementation.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "StrategicAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CorporateSustainabilityStrategyReporting",
    description:
      "Develop and report on corporate sustainability strategies, including goals, action plans, and performance tracking.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "RenewableEnergyInvestmentReporting",
    description:
      "Analyze and report on investments in renewable energy projects, including financial performance and environmental benefits.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SustainabilityRiskManagementReporting",
    description:
      "Identify and report on sustainability risks, including risk assessments, mitigation strategies, and monitoring.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SustainabilityBenchmarking",
    description:
      "Benchmark sustainability practices against industry standards to identify best practices and areas for improvement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EnvironmentalComplianceReporting",
    description:
      "Ensure and report on compliance with environmental regulations, including monitoring and reporting on environmental performance.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SocialImpactReporting",
    description:
      "Analyze and report on the social impact of corporate activities, including community development and social responsibility initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EnergyEfficiencyReporting",
    description:
      "Analyze and report on energy efficiency initiatives, including energy usage metrics and improvement efforts.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EthicalSourcingReporting",
    description:
      "Analyze and report on ethical sourcing practices, including supplier audits, compliance with ethical standards, and sustainability certifications.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "LifeCycleAssessmentReporting",
    description:
      "Conduct and report on life cycle assessments (LCAs) of products and services, evaluating their environmental impact from production to disposal.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SustainableInnovationReporting",
    description:
      "Analyze and report on sustainable innovation initiatives, including new technologies, products, and business models that promote sustainability.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "LifeCycleAssesmentAnalysis",
    description:
      "WIP Inputs !!! This is work in progress agent action ! Conduct and report on life cycle assessments (LCAs) of products and services, evaluating their environmental impact from production to disposal.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Sustainability",
    sc: 6,
    r: 0,
    wh: "",
  },
  {
    name: "GovernancePracticesReporting",
    description:
      "Generate reports on corporate governance practices, including board composition, governance policies, and compliance with regulations.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ESGReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "HumanRightsComplianceReporting",
    description:
      "Generate reports on human rights practices, including compliance with international standards and initiatives to protect human rights.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ESGReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ESGRiskManagementReporting",
    description:
      "Identify and report on ESG risks, including risk assessments, mitigation strategies, and monitoring.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ESGReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ESGBenchmarking",
    description:
      "Benchmark ESG practices against industry standards to identify best practices and areas for improvement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ESGReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ClimateChangeMitigationReporting",
    description:
      "Analyze and report on climate change mitigation strategies, including emissions reduction, carbon offset programs, and renewable energy initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ESGReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ESGDisclosureReporting",
    description:
      "Prepare and report on ESG disclosures in line with regulatory and voluntary standards, including GRI, SASB, and TCFD.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ESGReporting",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ProductLifecycleManagementReporting",
    description:
      "Generate reports on product lifecycle management, including development, launch, growth, maturity, and decline stages.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductPerformanceMetricsReporting",
    description:
      "Analyze and report on product performance metrics, including sales, market share, and profitability.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductDevelopmentProgressReporting",
    description:
      "Track and report on the progress of product development projects, including milestones, timelines, and deliverables.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerFeedbackIntegrationReporting",
    description:
      "Analyze and report on how customer feedback is integrated into product development and improvement processes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CompetitiveProductAnalysisReporting",
    description:
      "Analyze and report on competitive products, including features, pricing, market positioning, and strengths and weaknesses.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductInnovationReporting",
    description:
      "Analyze and report on product innovation initiatives, including new features, technologies, and market trends.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductRoadmapDevelopment",
    description:
      "Develop and report on product roadmaps, outlining future development plans, timelines, and strategic priorities.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "GoToMarketStrategyReporting",
    description:
      "Analyze and report on go-to-market strategies, including market entry plans, promotional tactics, and distribution channels.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductRevenueAnalysisReporting",
    description:
      "Analyze and report on product revenue, including sales trends, revenue growth, and financial performance metrics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductQualityReporting",
    description:
      "Analyze and report on product quality metrics, including defect rates, customer complaints, and quality improvement initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "MarketAcceptanceReporting",
    description:
      "Analyze and report on market acceptance of new products, including adoption rates, customer feedback, and market penetration.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductPortfolioManagementReporting",
    description:
      "Manage and report on the product portfolio, including portfolio balance, performance, and strategic alignment.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "UserExperienceReporting",
    description:
      "Analyze and report on user experience and satisfaction, including usability testing, user feedback, and experience improvements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductCostAnalysisReporting",
    description:
      "Analyze and report on product cost structures, including cost of goods sold (COGS), production costs, and cost optimization opportunities.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductRiskAssessmentReporting",
    description:
      "Identify and report on risks associated with product development and launch, including risk assessments and mitigation strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductLaunchEffectivenessReporting",
    description:
      "Analyze and report on the effectiveness of product launches, including launch metrics, sales performance, and market feedback.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductSustainabilityReporting",
    description:
      "Analyze and report on the sustainability aspects of product development and lifecycle, including eco-friendly materials, production processes, and disposal.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductCustomerSupportReporting",
    description:
      "Analyze and report on customer support metrics for products, including support ticket volumes, resolution times, and customer satisfaction.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductMarketResearchReporting",
    description:
      "Conduct and report on product market research studies, including market needs, competitive analysis, and customer preferences.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductComplianceReporting",
    description:
      "Ensure and report on product compliance with regulatory standards, including safety regulations, industry standards, and certification requirements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductStrategicAlignmentReporting",
    description:
      "Analyze and report on the strategic alignment of product initiatives with overall business goals and objectives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductInnovationPipelineReporting",
    description:
      "Analyze and report on the product innovation pipeline, including idea generation, concept development, and prioritization of new product ideas.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "CrossFunctionalCollaborationReporting",
    description:
      "Analyze and report on cross-functional collaboration in product development, including teamwork, communication, and project coordination.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "ProductSatisfactionSurveyReporting",
    description:
      "Conduct and report on product satisfaction surveys, gathering customer feedback on product features, performance, and overall satisfaction.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "ProductManagement",
    sc: 4,
    r: 0,
    wh: "",
  },
  {
    name: "MovingAverageAnalysis",
    description:
      "Analyze and report on moving averages, including Simple Moving Average (SMA), Exponential Moving Average (EMA), and their crossovers.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "RelativeStrengthIndexReporting",
    description:
      "Analyze and report on the Relative Strength Index (RSI) to identify overbought and oversold conditions in the market.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "BollingerBandsAnalysis",
    description:
      "Analyze and report on Bollinger Bands to identify volatility and potential price reversals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "MACDAnalysis",
    description:
      "Analyze and report on the Moving Average Convergence Divergence (MACD) indicator to identify trend changes and momentum.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "FibonacciRetracementAnalysis",
    description:
      "Analyze and report on Fibonacci retracement levels to identify potential support and resistance areas.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CandlestickPatternAnalysis",
    description:
      "Identify and report on various candlestick patterns, including Doji, Hammer, Engulfing, and their implications for price movements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "VolumeAnalysis",
    description:
      "Analyze and report on trading volume to identify trends, confirm price movements, and detect potential reversals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupportResistanceAnalysis",
    description:
      "Identify and report on key support and resistance levels, helping to forecast potential price movements and trend reversals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TrendLineAnalysis",
    description:
      "Analyze and report on trend lines to identify the direction and strength of market trends.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "StochasticOscillatorReporting",
    description:
      "Analyze and report on the Stochastic Oscillator to identify overbought and oversold conditions in the market.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ATRAnalysis",
    description:
      "Analyze and report on the Average True Range (ATR) to measure market volatility.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PivotPointsAnalysis",
    description:
      "Calculate and report on pivot points to identify potential support and resistance levels.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "IchimokuCloudAnalysis",
    description:
      "Analyze and report on the Ichimoku Cloud to identify support and resistance areas, trend direction, and momentum.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ParabolicSARAnalysis",
    description:
      "Analyze and report on the Parabolic SAR indicator to identify potential trend reversals.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ADXAnalysis",
    description:
      "Analyze and report on the Average Directional Index (ADX) to measure the strength of a trend.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ElliottWaveAnalysis",
    description:
      "Analyze and report on Elliott Wave patterns to predict future price movements based on historical price behavior.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "RateOfChangeAnalysis",
    description:
      "Analyze and report on the Rate of Change (ROC) indicator to measure the speed at which prices are changing.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "MomentumAnalysis",
    description:
      "Analyze and report on momentum indicators to assess the strength and velocity of price movements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "TechnicalAnalysis",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CyberSecurityRiskAnalysis",
    description:
      "Analyze the potential impact of security threats and vulnerabilities on an organization's systems and data. You develop risk assessment reports and recommend strategies to mitigate risks and improve security measures. You work closely with IT and security teams to implement risk management strategies and ensure compliance with cybersecurity regulations and best practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "IncidentResponseReporting",
    description:
      "Analyze and report on cybersecurity incidents, including data breaches, malware attacks, and other security breaches. You develop incident response reports and recommend strategies to prevent future incidents. You work closely with IT and security teams to improve incident response plans and ensure compliance with cybersecurity regulations and best practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "VulnerabilityAssessment",
    description:
      "Conduct vulnerability assessments to identify weaknesses in an organization's systems and networks. You develop vulnerability assessment reports and recommend strategies to mitigate vulnerabilities and improve security measures. You work closely with IT and security teams to implement vulnerability management strategies and ensure compliance with cybersecurity regulations and best practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ThreatIntelligenceReporting",
    description:
      "Analyze threat intelligence data to identify potential security threats and vulnerabilities. You develop threat intelligence reports and recommend strategies to prevent and respond to security threats. You work closely with IT and security teams to improve threat intelligence capabilities and ensure compliance with cybersecurity regulations and best practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ComplianceReporting",
    description:
      "Analyze and report on an organization's compliance with cybersecurity regulations and standards. You develop compliance reports and recommend strategies to improve compliance and security measures. You work closely with IT and security teams to ensure compliance with cybersecurity regulations and best practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SecurityAwarenessTrainingReporting",
    description:
      "Analyze and report on the effectiveness of security awareness training programs. You develop training effectiveness reports and recommend strategies to improve security awareness and reduce security risks. You work closely with IT and security teams to develop and implement security awareness training programs that comply with cybersecurity regulations and best practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PenetrationTestingReporting",
    description:
      "Conduct penetration tests to identify vulnerabilities in an organization's systems and networks. You develop penetration testing reports and recommend strategies to mitigate vulnerabilities and improve security measures. You work closely with IT and security teams to implement penetration testing strategies and ensure compliance with cybersecurity regulations and best practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DataBreachAnalysis",
    description:
      "Analyze data breaches to identify causes and develop mitigation strategies. You develop data breach analysis reports and recommend strategies to prevent future breaches. You work closely with IT and security teams to improve data breach response plans and ensure compliance with cybersecurity regulations and best practices.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "FirewallConfigurationReview",
    description:
      "Review firewall configurations to identify weaknesses and improve security measures. You develop firewall configuration review reports and recommend strategies to enhance firewall effectiveness. You work closely with IT and security teams to implement firewall configuration best practices and ensure compliance with cybersecurity regulations and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "IncidentResponsePlanAssessment",
    description:
      "Assess the adequacy of incident response plans to identify weaknesses and improve response capabilities. You develop incident response plan assessment reports and recommend strategies to enhance incident response plans. You work closely with IT and security teams to implement incident response plan best practices and ensure compliance with cybersecurity regulations and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SecurityPolicyReview",
    description:
      "Review security policies to identify weaknesses and improve security measures. You develop security policy review reports and recommend strategies to enhance security policy effectiveness. You work closely with IT and security teams to implement security policy best practices and ensure compliance with cybersecurity regulations and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "NetworkSecurityMonitoring",
    description:
      "Monitor network security to detect and prevent security breaches. You develop network security monitoring reports and recommend strategies to improve security measures. You work closely with IT and security teams to implement network security monitoring best practices and ensure compliance with cybersecurity regulations and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "MalwareAnalysisReporting",
    description:
      "Analyze and report on malware incidents and trends to identify potential security threats. You develop malware analysis reports and recommend strategies to prevent and respond to malware attacks. You work closely with IT and security teams to improve malware detection and response capabilities and ensure compliance with cybersecurity regulations and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EndpointSecurityReporting",
    description:
      "Analyze and report on the security of endpoints, including desktops, laptops, and mobile devices. You develop endpoint security reports and recommend strategies to improve endpoint security measures. You work closely with IT and security teams to implement endpoint security best practices and ensure compliance with cybersecurity regulations and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "IdentityAccessManagementReporting",
    description:
      "Analyze and report on identity and access management practices and issues to improve security measures. You develop identity access management reports and recommend strategies to enhance identity and access management capabilities. You work closely with IT and security teams to implement identity and access management best practices and ensure compliance with cybersecurity regulations and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PhishingAttackAnalysis",
    description:
      "Analyze and report on phishing attack trends and prevention measures to improve security awareness. You develop phishing attack analysis reports and recommend strategies to prevent and respond to phishing attacks. You work closely with IT and security teams to implement phishing attack prevention best practices and ensure compliance with cybersecurity regulations and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SecurityAuditReporting",
    description:
      "Conduct security audits to identify weaknesses and ensure compliance with cybersecurity regulations and best practices. You develop security audit reports and recommend strategies to improve security measures. You work closely with IT and security teams to implement security audit best practices and ensure compliance with cybersecurity regulations and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Cybersecurity",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerFeedbackAnalysis",
    description:
      "Analyzing customer feedback to uncover key trends and actionable insights.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "NPSReporting",
    description:
      "Measuring customer loyalty and satisfaction through Net Promoter Score (NPS) reporting.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerChurnAnalysis",
    description:
      "Examining customer churn rates to identify causes and develop retention strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerSatisfactionSurveyReporting",
    description:
      "Reporting on results from customer satisfaction surveys to gauge overall happiness.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerJourneyMapping",
    description:
      "Mapping out and analyzing the customer journey to improve touchpoint experiences.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerEngagementExperienceReporting",
    description:
      "Analyzing and reporting on customer engagement metrics and strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "VoiceOfCustomerAnalysis",
    description:
      "Analyse and create report Capturing and analyzing the Voice of the Customer (VoC) to improve products and services.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerLoyaltyProgramAnalysis",
    description:
      "Analyse and create reporton the effectiveness of customer loyalty programs.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OmnichannelExperienceAnalysis",
    description:
      "Analyse and create report on the customer experience across multiple channels.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerComplaintResolutionReporting",
    description:
      "Analyse and create report on the resolution of customer complaints and issues.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerSupportPerformanceReporting",
    description:
      "Analyse and create report on customer support performance metrics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerExperienceBenchmarking",
    description: "",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PersonalizedCustomerExperienceReporting",
    description:
      "Analyse and create report on personalized customer experience strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerRetentionRateReporting",
    description:
      "Analyse and create report on customer retention rates and strategies. ",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerExperienceROIAnalysis",
    description:
      "Measuring and reporting on the return on investment (ROI) of customer experience initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SocialMediaCustomerExperienceReporting",
    description:
      "Analyse and create report on customer experiences shared on social media platforms.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerEffortScoreReporting",
    description:
      "Measuring and reporting on customer effort scores to assess the ease of customer interactions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CustomerExperienceInnovationReporting",
    description:
      "Analyse and create report on innovative strategies to enhance the customer experience.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DigitalCustomerExperienceReporting",
    description:
      "Analyse and create report on the digital customer experience across web and mobile platforms.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "CustomerExperience",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OHSReporting",
    description:
      "Analyze and report on key workplace health and safety metrics to ensure compliance and improvement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "IncidentAccidentReporting",
    description:
      "Detail workplace incidents and accidents, including root causes and preventive measures.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SafetyTrainingReporting",
    description:
      "Report on the effectiveness, participation rates, and outcomes of safety training programs.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "HealthSafetyAuditReporting",
    description:
      "Summarize findings from health and safety audits to ensure compliance and highlight areas for improvement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "HazardRiskAssessmentReporting",
    description:
      "Identify workplace hazards and assess the associated risks to prioritize mitigation efforts.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ErgonomicsAssessmentReporting",
    description:
      "Evaluate and report on ergonomic risks and solutions in the workplace.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "WorkplaceWellnessReporting",
    description:
      "Analyze and report on workplace wellness programs and their impact on employee health.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmergencyPreparednessReporting",
    description:
      "Evaluate and report on the organization's preparedness for emergencies and disasters.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ComplianceIncidentReporting",
    description:
      "Report on incidents of non-compliance with health and safety regulations.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PPEUsageReporting",
    description:
      "Analyze and report on the usage and effectiveness of personal protective equipment (PPE).",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "WorkplaceInjuryStatisticsReporting",
    description:
      "Compile and report on statistics related to workplace injuries and illnesses.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OccupationalHealthSurveillanceReporting",
    description:
      "Monitor and report on occupational health trends and concerns.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SafetyCultureAssessmentReporting",
    description:
      "Evaluate and report on the safety culture within the organization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "WorkplaceViolencePreventionReporting",
    description:
      "Analyze and report on initiatives and incidents related to workplace violence prevention.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PandemicPreparednessReporting",
    description:
      "Evaluate and report on the preparedness and response to pandemics in the workplace.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EHSManagementSystemReporting",
    description:
      "Report on the implementation and effectiveness of Environmental, Health, and Safety (EHS) management systems.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HealthAndSafety",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DiversityMetricsReporting",
    description:
      "Generate detailed reports on workforce diversity, including demographic breakdowns and representation metrics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "InclusionInitiativesReporting",
    description:
      "Analyze and report on the effectiveness of initiatives aimed at fostering workplace inclusion.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EqualOpportunityReporting",
    description:
      "Evaluate and report on equal opportunity practices within the organization to ensure fair treatment for all employees.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DiversityTrainingReporting",
    description:
      "Report on the effectiveness, participation, and outcomes of diversity and inclusion training programs.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeResourceGroupReporting",
    description:
      "Analyze the activities, participation, and impact of Employee Resource Groups within the organization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "GenderPayGapReporting",
    description:
      "Analyze and report on gender pay gap metrics to highlight discrepancies in pay based on gender.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CulturalCompetenceReporting",
    description:
      "Evaluate and report on the cultural competence of the workforce, including training and development initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DiversityRecruitmentReporting",
    description:
      "Analyze and report on the effectiveness of recruitment strategies aimed at increasing workforce diversity.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "BiasIncidentReporting",
    description:
      "Document and report on incidents of bias within the workplace, including analysis of trends and resolutions.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "AccessibilityInitiativesReporting",
    description:
      "Evaluate and report on the effectiveness and impact of initiatives aimed at improving workplace accessibility.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DiversityClimateSurveyReporting",
    description:
      "Conduct and report on surveys that assess the diversity climate and inclusiveness of the workplace.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DiversityScorecardReporting",
    description:
      "Develop and report on diversity scorecards to monitor and track the progress of diversity initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DiversityBenchmarking",
    description:
      "Benchmark organizational diversity metrics against industry standards and best practices to identify areas for improvement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "MulticulturalInitiativesReporting",
    description:
      "Analyze and report on initiatives aimed at promoting multicultural understanding and collaboration within the workplace.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeDiversitySurveyReporting",
    description:
      "Conduct and report on surveys that capture employee perceptions and experiences related to diversity in the workplace.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DiversityAndInclusion",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplierPerformanceReporting",
    description:
      "Analyze and report on key performance metrics of suppliers, including delivery times, quality, and reliability.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SustainableProcurementReporting",
    description:
      "Evaluate and report on the sustainability practices of procurement processes, including environmental and social impacts.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplyChainRiskAnalysis",
    description:
      "Identify, evaluate, and report on potential risks within the supply chain, including mitigation strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplyInventoryManagementReporting",
    description:
      "Analyze and report on inventory management, including stock levels, turnover rates, and optimization strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ProcurementCostAnalysis",
    description:
      "Analyze and report on procurement costs, identifying opportunities for cost savings and efficiency improvements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Procurement",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplyChainEfficiencyReporting",
    description:
      "Evaluate and report on the efficiency of supply chain operations, identifying bottlenecks and areas for improvement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplierDiversityReporting",
    description:
      "Analyze and report on supplier diversity, including efforts to engage diverse suppliers and the impact of these initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "LogisticsPerformanceReporting",
    description:
      "Analyze and report on logistics performance, including transportation efficiency, delivery times, and cost effectiveness.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ProcurementComplianceReporting",
    description:
      "Ensure and report on compliance with procurement policies, regulations, and standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Procurement",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DemandForecastingReporting",
    description:
      "Forecast and report on demand for products and services, helping to align supply chain operations with market needs.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplierRelationshipManagementReporting",
    description:
      "Analyze and report on supplier relationship management, focusing on collaboration, performance, and strategic partnerships.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplyChainResilienceReporting",
    description:
      "Evaluate and report on the resilience of the supply chain, identifying vulnerabilities and strategies to enhance robustness.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ProcurementProcessOptimization",
    description:
      "Analyze and report on procurement process optimization, aiming to improve efficiency, reduce costs, and enhance performance.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "Procurement",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplyChainSustainabilityProcurementReporting",
    description:
      "Evaluate and report on the sustainability of supply chain practices, including environmental and social impacts.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplierPerformanceScorecard",
    description:
      "Develop and report on supplier performance scorecards, assessing suppliers on key performance indicators.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SupplyChain",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeEngagementReporting",
    description:
      "Generate reports on employee engagement, including survey results and engagement metrics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "TrainingDevelopmentReporting",
    description:
      "Analyze and report on the effectiveness of training and development programs, including participation and outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "WorkforceDemographicsReporting",
    description:
      "Generate reports on workforce demographics, including age, gender, ethnicity, and other key metrics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "PerformanceAppraisalReporting",
    description:
      "Analyze and report on employee performance appraisals, including ratings, feedback, and development plans.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "RecruitmentMetricsReporting",
    description:
      "Generate reports on recruitment metrics, including time-to-fill, cost-per-hire, and applicant quality.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeTurnoverReporting",
    description:
      "Analyze and report on employee turnover, including turnover rates, reasons for leaving, and retention strategies.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CompensationBenefitsReporting",
    description:
      "Generate reports on compensation and benefits programs, including salary benchmarks and benefits utilization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "HRComplianceReporting",
    description:
      "Ensure and report on compliance with HR policies, labor laws, and regulations.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeSatisfactionSurveyReporting",
    description:
      "Analyze and report on employee satisfaction survey results, highlighting key findings and action plans.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SuccessionPlanningReporting",
    description:
      "Generate reports on succession planning efforts, including talent assessments and development plans.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "HRAnalyticsReporting",
    description:
      "Utilize data analytics to generate insights and reports on key HR metrics and trends.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "RemoteWorkEffectivenessReporting",
    description:
      "Analyze and report on the effectiveness of remote work programs, including productivity and employee satisfaction.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "DEIReporting",
    description:
      "Generate reports on diversity, equity, and inclusion (DEI) metrics and initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeOnboardingReporting",
    description:
      "Analyze and report on employee onboarding processes, including time-to-productivity and new hire satisfaction.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeRetentionStrategiesReporting",
    description:
      "Develop and report on strategies aimed at improving employee retention and reducing turnover.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "LeadershipDevelopmentReporting",
    description:
      "Generate reports on leadership development programs, including participation rates and impact on leadership effectiveness.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeWellnessProgramsReporting",
    description:
      "Analyze and report on employee wellness programs, including participation and impact on employee health.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "LaborRelationsReporting",
    description:
      "Generate reports on labor relations, including union interactions and collective bargaining agreements.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SkillsGapAnalysisReporting",
    description:
      "Identify and report on skills gaps within the workforce, including training needs and development plans.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CareerDevelopmentProgramsReporting",
    description:
      "Generate reports on career development programs, including participation rates and career progression outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeRecognitionProgramsReporting",
    description:
      "Analyze and report on employee recognition programs, including participation and impact on employee motivation.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "HRISImplementationReporting",
    description:
      "Manage and report on the implementation of HRIS, including system adoption and impact on HR processes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "WorkforcePlanningReporting",
    description:
      "Generate reports on workforce planning efforts, including future staffing needs and succession planning.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ConflictResolutionReporting",
    description:
      "Analyze and report on conflict resolution cases, including mediation processes and outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "HRBudgetReporting",
    description:
      "Manage and report on the HR department budget, including tracking expenditures and identifying cost-saving opportunities.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeFeedbackLoopReporting",
    description:
      "Generate reports on the effectiveness of employee feedback loops in improving job satisfaction and engagement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EmployeeEngagementSurveyReporting",
    description:
      "Conduct and report on employee engagement surveys, highlighting key findings and action plans.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "HumanResources",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ProductionEfficiencyReporting",
    description:
      "Generate detailed reports on production efficiency, including throughput, cycle time, and resource utilization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "QualityControlReporting",
    description:
      "Analyze and report on quality control metrics, including defect rates, rework, and compliance with quality standards.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EquipmentUtilizationReporting",
    description:
      "Analyze and report on equipment utilization metrics, including uptime, downtime, and overall equipment effectiveness (OEE).",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CostEfficiencyReporting",
    description:
      "Generate reports on cost efficiency, identifying areas for cost savings and improved financial performance.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SupplyChainPerformanceReporting",
    description:
      "Analyze and report on supply chain performance, including lead times, delivery performance, and supplier reliability.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OperationalKPIReporting",
    description:
      "Generate detailed reports on key operational performance indicators (KPIs) to track and improve performance.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "LeanSixSigmaReporting",
    description:
      "Analyze and report on Lean Six Sigma projects, including process improvements, waste reduction, and efficiency gains.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OperationalRiskManagementReporting",
    description:
      "Identify and report on operational risks, including risk assessments, mitigation strategies, and monitoring.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "MaintenancePerformanceReporting",
    description:
      "Analyze and report on maintenance performance, including preventive maintenance, repair times, and reliability metrics.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "CapacityUtilizationReporting",
    description:
      "Generate reports on capacity utilization, including analysis of production capacity and bottlenecks.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OperationalExcellenceReporting",
    description:
      "Analyze and report on operational excellence initiatives, including best practices, benchmarks, and continuous improvement efforts.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ProductivityAnalysisReporting",
    description:
      "Analyze and report on productivity metrics, including labor productivity, machine efficiency, and overall productivity trends.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OperationalBudgetManagementReporting",
    description:
      "Manage and report on operational budgets, including tracking expenditures and identifying cost-saving opportunities.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "EnvironmentalPerformanceReporting",
    description:
      "Analyze and report on environmental performance, including energy usage, emissions, and sustainability initiatives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SafetyPerformanceReporting",
    description:
      "Generate reports on safety performance, including incident rates, safety audits, and compliance with safety regulations.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ProcessOptimizationReporting",
    description:
      "Analyze and report on process optimization initiatives, including improvements in efficiency, quality, and cost reduction.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OperationalBenchmarking",
    description:
      "Benchmark operational performance against industry standards to identify best practices and areas for improvement.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "IncidentReportingAnalysis",
    description:
      "Analyze and report on operational incidents, including root cause analysis, corrective actions, and lessons learned.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "ResourceAllocationReporting",
    description:
      "Analyze and report on resource allocation and utilization, including labor, materials, and equipment.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "OperationalProcessAuditReporting",
    description:
      "Conduct and report on operational process audits, including compliance with standards and identification of improvement areas.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "OperationalPerformance",
    sc: 0,
    r: 0,
    wh: "",
  },
  {
    name: "SyntheticDatasetAnalysis",
    description:
      "Generate synthetic datasets for analysis and testing purposes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SyntheticData",
    sc: 5,
    r: 0,
    wh: "",
  },
  {
    name: "GenerateDataForTestingAndAnalysis",
    description:
      "Generate synthetic datasets in provided format for analysis and testing purposes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SyntheticData",
    sc: 1,
    r: 10,
    wh: "",
  },
  {
    name: "GenerateSamplesAndFeatures",
    description:
      "Generate synthetic datasets from provided data for analysis and testing purposes. Preserves the statistical properties, distributions, means and variance of the original data",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SyntheticData",
    sc: 1,
    r: 10,
    wh: "",
  },
  {
    name: "GenerateDataWithDistribution",
    description:
      "Generate synthetic datasets from provided data for analysis and testing purposes. Preservers distribution of original data.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SyntheticData",
    sc: 1,
    r: 10,
    wh: "",
  },
  {
    name: "FakeItTillYouMakeIt",
    description:
      "Generate synthetic datasets from provided data. Fake it till you make it.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "SyntheticData",
    sc: 1,
    r: 10,
    wh: "",
  },
  {
    name: "DesignManagementStrategicPlanning",
    description:
      "Develop a strategic plan for design management to align design efforts with business objectives.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "DesignManagementBrandManagement",
    description:
      "Maintain a consistent brand identity and ensure all design work supports the brand.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "DesignManagementProductDevelopment",
    description:
      "Manage the design process in product creation, from concept to final product.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "DesignManagementInnovation",
    description:
      "Encourage and manage creative processes to drive innovation within the organization.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "DesignManagementProcessImprovement",
    description:
      "Streamline design processes to improve efficiency and productivity.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "DesignManagementCrossFunctionalCollaboration",
    description:
      "Coordinate between different departments (marketing, engineering, production) to ensure cohesive design implementation.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "DesignManagementUserExperience",
    description:
      "Focus on the end-user experience to ensure products and services meet user needs and preferences.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "DesignManagementSustainability",
    description:
      "Incorporate sustainable design practices to minimize environmental impact.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "DesignManagementTeamTopology",
    description:
      "Optimize team structure and dynamics to enhance design collaboration and productivity.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 7,
    r: 0,
    wh: "",
  },
  {
    name: "DesignManagementMachineLearning",
    description:
      "Leverage machine learning algorithms and models to enhance design processes and outcomes.",
    providerNodeId: "33a61761aa154073bf81474d269399b0",
    resource: "",
    data: "",
    owner: "",
    connectionId: "",
    fileTypes: ".txt, .md, .csv, .json",
    s: "",
    sm: "",
    project: "",
    m: null,
    time: 1729769817,
    d: 0,
    g: "DesignManagement",
    sc: 15,
    r: 0,
    wh: "",
  },
];

export default staticNodeActions;
