import React, { useEffect, useState, useCallback, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./components/Modal";
import ProgressBar from "./components/ProgressBar";
import "./App.css";
import Web3, { net } from "web3";
import * as signalR from "@microsoft/signalr";
import Terms from "./parts/terms";
import Footer from "./Footer";
import UserTree from "./components/UserTree";
import UserMenu from "./components/UserMenu";
import SpaceUser from "./components/SpaceUser";
import Markdown from "react-markdown";
import ActionMarketplace from "./components/ActionMarketplace";
import GroupsMarketplace from "./components/GroupsMarketplace";
import CharactersMarketplace from "./components/CharactersMarketplace";
import BottomScrollingPanel from "./components/ui/BottomScrollingPanel";

// import remarkGfm from "remark-gfm";
// import remarkMermaid from "remark-mermaidjs";
// import remarkMath from "remark-math";
// import rehypeMermaid from "rehype-mermaid";

import CytoGraph from "./components/CytoGraph";
import MarkmapView from "./components/MarkmapView";
import Spinner from "./components/Spinner";
import CharacterCard from "./components/ui/CharacterCard";

const assetName = "SDF"; //"BZZ");
var tokenDecimals = 16; // important (16 for BZZ, 18 for ETH)
const APPNAME = "Spaces";

const tokenABI = require("./contracts/erc20.abi.json");
const serviceABI = require("./contracts/service.abi.json");

const networkInfo = [
  {
    name: "localhost", //0
    chainId: 31337,
    token: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
    service: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
    hub: "https://localhost:7168/dataRelayHub",
    rpc: "http://localhost:8545",
    blockExplorer: "https://localhost:8545",
    currencyName: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  {
    name: "Sepolia_with_LocalService", //1
    chainId: 11155111,
    token: "0xF9E163864a73867F6a448506288446A460f31b3f",
    service: "0x1Af33Bf59D0E3bd09C15a29Cb62428D5c025eCFc",
    hub: "https://localhost:7168/dataRelayHub", //"https://relay.datafund.io:7168/dataRelayHub",
    rpc: "https://sepolia.dev.fairdatasociety.org",
    blockExplorer: "https://sepolia.etherscan.io/",
    currencyName: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  {
    name: "Sepolia", //2
    chainId: 11155111,
    token: "0xF9E163864a73867F6a448506288446A460f31b3f",
    service: "0x1Af33Bf59D0E3bd09C15a29Cb62428D5c025eCFc",
    hub: "https://relay.datafund.io/dataRelayHub",
    rpc: "https://sepolia.dev.fairdatasociety.org",
    blockExplorer: "https://sepolia.etherscan.io/",
    currencyName: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  {
    name: "degen", //3
    chainId: 666666666,
    token: "0x99e6A44a8Dd93cF3CEbaC00D1B7c5D20A076a0De",
    service: "0x2411496aCc1e2F875D85870F1DE3B5419D7cA5fD",
    hub: "https://relay.datafund.io/dataRelayHub",
    rpc: "https://rpc.degen.tips",
    blockExplorer: "https://explorer.degen.tips/",
    currencyName: "Degen",
    symbol: "DEGEN",
    decimals: 18,
  },
  {
    name: "degen_with_localService", //4
    chainId: 666666666,
    token: "0x99e6A44a8Dd93cF3CEbaC00D1B7c5D20A076a0De",
    service: "0x2411496aCc1e2F875D85870F1DE3B5419D7cA5fD",
    hub: "https://localhost:7168/dataRelayHub",
    rpc: "https://rpc.degen.tips",
    blockExplorer: "https://explorer.degen.tips/",
    currencyName: "Degen",
    symbol: "DEGEN",
    decimals: 18,
  },
];

const isProduction = process.env.NODE_ENV === "production";
console.log("isProduction", isProduction);
// DEV VARIABLES
///////////////////////////////
const network = networkInfo[isProduction ? 3 : 0];
///////////////////////////////
const supportedChainId = network.chainId;
//const tokenAddress = network.token;
//const serviceAddress = network.service;
const DATA_RELAY_HUB_URL = network.hub;
console.log("network", network);

var currentChainId = "";

function dummyStub(arg1, arg2, arg3) {
  return;
}
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 B";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var len = binaryString.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
// // https://github.com/imaya/zlib.js
// // import Zlib from "./zlib.pretty.js";
// function decompressString(base64) {
//   var data = atob(base64);
//   var compressed = new Uint8Array(data.length / 2);

//   for (var i = 0; i < data.length; i += 2) {
//     compressed[i / 2] = parseInt(data.substring(i, i + 2), 16);
//   }

//   var inflate = new Zlib.Gunzip(compressed);
//   var decompressed = inflate.decompress();
//   var text = new TextDecoder().decode(decompressed);
//   return text;
// }

window.formatBytes = formatBytes;

function isMobileBrowser() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Patterns for mobile devices
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    userAgent
  );
}
/*
if (isMobileBrowser()) {
  console.log("You are using a mobile browser.");
} else {
  console.log("You are not using a mobile browser.");
}
  */

export const connectMetamaskMobile = () => {
  const dappUrl = window.location.href.split("//")[1].split("/")[0];
  const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
  // https://metamask.app.link/dapp/spaces.datafund.io
  window.open(metamaskAppDeepLink, "_self");
};

function App() {
  const [isSearchingActive, setIsSearchingActive] = useState(false);
  const [serviceInfo, setServiceInfo] = useState({});
  const [web3, setWeb3] = useState(undefined);
  const [networkStatus, setNetworkStatus] = useState("");

  const [nodeTokenAddress, setNodeTokenAddress] = useState(null);
  const [nodeServiceAddress, setNodeServiceAddress] = useState(null);
  const [nodeChainId, setNodeChainId] = useState(null);
  const [nodeRpc, setNodeRpc] = useState(null);
  const [nodeBlockExplorer, setNodeBlockExplorer] = useState(null);
  const [nodeCurrencyName, setNodeCurrencyName] = useState(null);
  const [nodeSymbol, setNodeSymbol] = useState(null);
  const [nodeHub, setNodeHub] = useState(null);

  const [tokenContract, setTokenContract] = useState(undefined); // [1
  const [serviceContract, setServiceContract] = useState(undefined);
  const [hub, setHub] = useState(undefined); // [1
  const [connected, setConnected] = useState(false);
  const [connectionId, setConnectionId] = useState("");

  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [isAgreed, setIsAgreed] = useState(
    localStorage.getItem("agreed_to_terms") === "1"
  ); // terms

  const [streamStatus, setStreamStatus] = useState("");
  const [amount, setAmount] = useState(1);

  const [mainMarkdown, _setMainMarkdown] = useState(null);
  const [completedTask, setCompletedTask] = useState(null); // tasks that are completed

  const [users, setUsers] = useState([]); // users in network (from node)
  const [spaceUsers, setSpaceUsers] = useState([]); // users in space (from search)
  const [nodeActions, setNodeActions] = useState([]); // actions node can perform
  const [nodeCharacters, setNodeCharacters] = useState([]); // characters node can perform
  const [processingQueueInfo, setProcessingQueueInfo] = useState([]); // actions node can perform
  //const [spaceAction, setSpaceAction] = useState({ name: "" }); // action to perform on spaceUser
  const [spaceActions, setSpaceActions] = useState([]); // actions user performed and is waiting for responses

  const [viewCharacterPanel, setViewCharacterPanel] = useState(null);

  // spaceUser: { user. collections, manifests, contents }
  const [userAddress, setUserAddress] = useState(""); // user address we are viewing

  var [spaceNodes, setSpaceNodes] = useState([]);
  const [spaceNode, _setSpaceNode] = useState(null);

  const [currentUserData, setCurrentUserData] = useState({});
  const [selectedUser, _setSelectedUser] = useState(null);
  const [userContents, setUserContents] = useState([]);
  const [userContent, setUserContent] = useState("");

  const [userProjects, setUserProjects] = useState([]);
  const [userProject, setUserProject] = useState(null);

  const [manifests, setManifests] = useState([]);
  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState(null);

  const [fileContents, setFileContents] = useState(null);
  const [outStandingPayments, setOutStandingPayments] = useState([]);
  const [resourceContents, setResourceContents] = useState(null);
  const [resourceCharacterAnswers, setResourceCharacterAnswers] = useState([]);

  const [balance, setBalance] = useState(0); // eth balance
  const [tokenBalance, setTokenBalance] = useState(0); // token balance

  const [address, setAddress] = useState("");
  const [hash, setHash] = useState("");
  const [resourceToDownload, setResourceToDownload] = useState(null);

  const [isDownloaderVisible, setIsDownloaderVisible] = useState(false);
  //   "12e3bf6ae5de212eb82b733eb2b571187b8b3d9ff95243ac176febf2033b800d"
  // );
  const [paymentResource, setPaymentResource] = useState(undefined);
  const [paymentFilename, setPaymentFilename] = useState(undefined);
  const [paymentSize, setPaymentSize] = useState(0);
  const [paymentPrice, setPaymentPrice] = useState(0);
  const [dataowner, setDataowner] = useState("");
  const [beneficiary, setBeneficiary] = useState("");
  const [storageConsumed, setStorageConsumed] = useState(0);
  const [paymentTxHash, setPaymentTxHash] = useState("");

  const [downloadTime, setDownloadTime] = useState(0);
  const [downloadedSize, setDownloadedSize] = useState(0);
  const [downloadPercentage, setDownloadPercentage] = useState(0);

  const isSearchingActiveRef = useRef(isSearchingActive);
  const spaceUsersRef = useRef(spaceUsers); // Add useRef here
  const spaceActionsRef = useRef(spaceActions); // Add useRef here
  const usersRef = useRef(users); // Add useRef here
  const nodeActionRef = useRef(nodeActions); // Add useRef here
  const projectsRef = useRef(userProjects); // Add useRef here
  const nodeCharacterRef = useRef(nodeCharacters); // Add useRef here

  async function setMainMarkdown(markdown) {
    // check to see if markdown contains yaml or mermaid block, if so extract block
    _setMainMarkdown(markdown);
  }
  async function setSelectedUser(user) {
    _setSelectedUser(user);
    // if (user === undefined) {
    //   toast(<h3>User not existent</h3>);
    //   return;
    // }
    if (user !== null) setUserAddress(user?.address);

    setManifests([]);

    setCollections([]);
    setCollection(null);

    setUserContents([]);
    setUserContent(null);
  }
  async function setSpaceNode(node) {
    console.log("setSpaceNode", node);
    _setSpaceNode(node);
    if (node === null) return;
    if (node.nodeId === serviceInfo.nodeClient?.nodeId) {
      toast("You are connected to this node");
    } else {
      toast("This node does not allow connections");
    }

    setManifests([]);
    setUserContents([]);
    setCollections([]);
    setCollection(null);
  }

  async function checkChain() {
    if (window.ethereum) {
      currentChainId = parseInt(
        await window.ethereum.request({ method: "eth_chainId" }),
        16
      );
    }
  }

  async function goToUserHome() {
    // currentUserData
    console.log("going home " + address);
    setSelectedUser(currentUserData.user);

    setUserContents([]);
    setUserProjects([]);
    setManifests([]);

    // await hub.invoke("GetUserContents", address);
    await hub.invoke("GetUserProjects", address);
    await hub.invoke("GetUserContents", address);
    await window.history.pushState(null, null, address); // add url to user http://localhost:3000/userAddress
  }

  useEffect(() => {
    const full_uri = window.location.href; // gives full url
    const path = window.location.pathname; // could be /a3ef627ebd1f2e0c304f03cdd11954b4e42bcc33c52b2c6e9c7e23ad9fea8e6a in wich case user wants to download directly
    const queryParams = new URLSearchParams(window.location.search);
    const paramHash = queryParams.get("hash"); // get hash if specified
    const paramUser = queryParams.get("user"); // get user if specified
    const resourceToDownload = queryParams.get("download"); // get resource to download if specified

    if (paramHash) setHash(paramHash);
    if (paramUser) setUserAddress(paramUser);

    if (resourceToDownload) {
      setHash(resourceToDownload);
      setResourceToDownload(resourceToDownload);
    } else {
      // check if path is in form /a3ef627ebd1f2e0c304f03cdd11954b4e42bcc33c52b2c6e9c7e23ad9fea8e6a and same length
      if (path.length === 65) {
        setHash(path.substring(1));
        setResourceToDownload(path.substring(1));
        console.log("direct path", path.substring(1));
      }
    }

    setNetworkStatus("Checking chain");
    // call checkChain
    checkChain();

    setNetworkStatus("Params validation");

    console.log("params", paramHash, paramUser, full_uri, path);
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(DATA_RELAY_HUB_URL) // Update this URL to match your SignalR Hub endpoint
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    setNetworkStatus("Starting up");

    connection
      .start()
      .then(function () {
        console.log("Connected!");
        setNetworkStatus("Connected !");
        //toast("Connected");
        setConnectionId(connection.connectionId);
        setHub(connection);

        connection.invoke("GetServiceLoad");
      })
      .catch(function (err) {
        console.log("hub " + err.toString());
        setConnected(false);
        setConnectionId("");
        setHub(undefined);
        setNetworkStatus("Failure");
        //toast("Error connecting to hub");
        return err.toString();
      });

    connection.onclose((error) => {
      setNetworkStatus("Closed");
      console.error("Connection on close", error);
      toast.error("Connection closed " + error);
      setHub(undefined);
      setConnected(false);
    });
    connection.on("KeepAlive", () => {
      console.log("Alive");
      setNetworkStatus("Alive");
    });
    connection.on("ReceiveMessage", (user, message) => {
      if (message === "") toast(`${user}`);
      else if (user === "") toast(`${message}`);
      else toast(`${user}: ${message}`);
    });
    connection.on("ReceiveResource", (resource, content) => {
      toast(`Received: ${resource}: ${content.substring(0, 20)}...`);
    });
    connection.on("ReceiveMarkdown", (user, message) => {
      console.log("ReceiveMarkdown", user, message);
      setMainMarkdown("# " + user + "\n" + message);
      //toast(<Markdown>{message}</Markdown>);
    });
    connection.on("ReceiveCharacterAnswer", (resource, codename, answer) => {
      console.log("ReceiveCharacterAnswer", resource, codename, answer);
      //toast(`${resource} ${codename}: ${answer}`);
      // add answer to a list of character answers
      const characterAnswer = { resource, codename, answer };
      // add to resourceCharacterAnswers
      setResourceCharacterAnswers((prevAnswers) => [
        characterAnswer,
        ...prevAnswers,
      ]);
    });
    connection.on("ReceivePaymentConfirmation", (user, message) => {
      toast(`${user}: ${message}`);
    });
    connection.on("Disconnect", (message) => {
      toast(message);
      setConnected(false);
    });
    connection.on("ReceiveServiceLoad", (info) => {
      console.log(
        "ReceiveServiceLoad",
        info,
        info.ethService.service,
        network.service
      );
      setServiceInfo(info);

      // you will have to reinstantiate the tokenContract, serviceContract
      setNodeTokenAddress(info.ethService.token);
      setNodeServiceAddress(info.ethService.service);
      setNodeChainId(info.ethService.chainId);
      setNodeRpc(info.ethService.rpc);
      setNodeBlockExplorer(info.ethService.blockExplorer);
      setNodeCurrencyName(info.ethService.currency);
      setNodeSymbol(info.ethService.symbol);
      setNodeHub(info.ethService.hub);

      if (info.ethService.service !== network.service) {
        console.error("Service address mismatch");
        toast(
          "Token address mismatch. Client And Service settings do not match"
        );
        // TODO: this should be handled better, in case client and service are not with same settings
      }
    });
    connection.on("ReceiveBalance", (balance) => {
      setTokenBalance(balance);
      console.log("Token Balance: " + balance);
      //toast("Balance: " + balance);
    });
    connection.on(
      "PaymentRequest",
      (paymentRequestId, fileName, price, size, dataowner) => {
        (parseFloat(price) * Math.pow(10, tokenDecimals)).toString();
        console.log(
          "PaymentRequest",
          paymentRequestId,
          fileName,
          price,
          size,
          dataowner
        );
        paymentRequest(paymentRequestId, fileName, price, size, dataowner);
      }
    );
    connection.on(
      "ReceiveData",
      (paymentConfirmationId, fileName, price, size) => {
        console.log(
          "ReceiveData",
          paymentConfirmationId,
          fileName,
          price,
          size
        );
        setDownloadedSize(0);
        setDownloadPercentage(0);
        setPaymentSize(size);
        receiveData(connection, paymentConfirmationId, fileName, price, size);
      }
    );
    // Pay For Storage
    connection.on(
      "OutstandingPaymentRequest",
      (
        paymentRequestId,
        fileName,
        price,
        size,
        dataowner,
        beneficiary,
        consumedInBytes
      ) => {
        (parseFloat(price) * Math.pow(10, tokenDecimals)).toString();
        console.log(
          "OutstandingPaymentRequest",
          paymentRequestId,
          fileName,
          price,
          size,
          dataowner,
          beneficiary,
          consumedInBytes
        );
        paymentRequest(
          paymentRequestId,
          fileName,
          price,
          size,
          dataowner,
          beneficiary,
          consumedInBytes
        );
      }
    );
    connection.on(
      "PayForStorageRequest",
      (
        paymentRequestId,
        fileName,
        price,
        size,
        dataowner,
        beneficiary,
        consumedInBytes
      ) => {
        (parseFloat(price) * Math.pow(10, tokenDecimals)).toString();
        console.log(
          "PayForStorageRequest",
          paymentRequestId,
          fileName,
          price,
          size,
          dataowner,
          beneficiary,
          consumedInBytes
        );
        paymentRequest(
          paymentRequestId,
          fileName,
          price,
          size,
          dataowner,
          beneficiary,
          consumedInBytes
        );
      }
    );
    connection.on(
      "OutstandingPaymentConfirmation",
      (filename, price, size, resource) => {
        console.log(
          "OutstandingPaymentConfirmation",
          filename,
          price,
          size,
          resource
        );
        setBeneficiary(undefined); // beneficiary is important for OutstandingPayment Confirmation
        toast("Outstanding payment confirmed " + filename);
      }
    );
    connection.on(
      "PayForStorageConfirmation",
      (filename, price, size, resource) => {
        console.log(
          "PayForStorageConfirmation",
          filename,
          price,
          size,
          resource
        );
        setBeneficiary(undefined);
        toast("Storage Space Reserved");
      }
    );
    connection.on("ReceiveUserData", (data) => {
      console.log("ReceiveUserData", data);
      //setOutStandingPayments(data.outstandingPayments);
      setCurrentUserData(data);
    });
    connection.on("UserCollection", (name, contents) => {
      // decode contents from base64
      var c = atob(contents);
      try {
        c = JSON.parse(c);
        setCollection(c);
        console.log("UserCollection", name, contents, c);
      } catch (e) {
        console.error("Error decoding collection", e);
        toast.error("Error decoding collection");
        setCollection(null);
        //setCollection(atob(contents));
      }
    });
    connection.on("FileContents", (name, contents) => {
      console.log("FileContents", name, contents);
      setFileContents(contents);
    });
    connection.on("Verified", (address) => {
      console.log("Verified", address);
      console.log("resourceToDownload", resourceToDownload);
      if (resourceToDownload) {
        receiveResource(resourceToDownload);
      }
      //getUsers(address);
    });
    connection.on("UploadFinished", (file) => {
      console.log("UploadFinished", file);
      toast("Upload Finished " + file);
    });
    /////////////////////////////////////////////////
    connection.on("ReceiveManifests", (resources) => {
      console.log("ReceiveManifests", resources);
      setManifests(resources);
      resources.forEach((resource) => {
        console.log(resource);
      });
    });
    connection.on("ReceiveContents", (userContentsObj) => {
      console.log("ReceiveContents", userContentsObj);
      var userContents = JSON.parse(userContentsObj);
      var dym = {};
      // go through all properties or object, decode from base64 and store in var that later becomes userContents
      for (const [key, value] of Object.entries(userContents)) {
        if (key === "address") {
          // check if it starts with 0x
          if (value.startsWith("0x")) dym[key] = value;
          else {
            try {
              dym[key] = atob(value);
            } catch (e) {
              dym[key] = value;
            }
          }
          continue;
        }
        if (key === "image") {
          var img = new Image();
          img.src = "data:image/png;base64," + value;
          dym[key] = img.src;
        } else {
          try {
            var decodedString = atob(value);
            if (decodedString.length > 0) dym[key] = atob(value);
          } catch (e) {
            //console.log("error decoding", e);
            dym[key] = value;
          }
        }
      }
      setUserContents(dym);
      console.log("ReceiveContents decoded", dym);
    });
    connection.on(
      "ReceiveResourceContents",
      (resourceName, resourceData, isBinary) => {
        var data = resourceData;
        if (isBinary) {
          //data = base64ToArrayBuffer(resourceData);
          // check to see if contents are plain text or base64
          data = atob(resourceData);
          // Function to convert Base64 string to ArrayBuffer
        }

        setResourceContents({
          name: resourceName,
          data: resourceData,
          isBinary: isBinary,
        });

        // console.log(
        //   "ReceiveResourceContents",
        //   isBinary,
        //   resourceName,
        //   resourceData,
        //   data
        // );
      }
    );

    connection.on("ReceiveContent", (userContent) => {
      console.log("ReceiveContent", userContent);
      try {
        setUserContent(atob(userContent));
      } catch (e) {
        setUserContent(userContent);
      }
    });

    connection.on("ReceiveSpaceAction", (action) => {
      const prevSpaceActions = spaceActionsRef.current;
      var newSpaceActions = [];

      for (let i = 0; i < prevSpaceActions.length; i++) {
        console.log("compare:", prevSpaceActions[i], action);
        if (
          !(
            prevSpaceActions[i].resource === action.resource &&
            prevSpaceActions[i].name === action.name
          )
        ) {
          newSpaceActions.push(prevSpaceActions[i]);
          console.log(
            "pushed ",
            prevSpaceActions[i].resource,
            prevSpaceActions[i].name
          );
        }
      }
      // // filter out action from spaceActions (same name / same project)
      // const filteredActions = prevSpaceActions.filter(
      //   (a) => a.name !== action.name || a.project !== action.project
      // );
      setSpaceActions(newSpaceActions);
      console.log("ReceiveSpaceAction", action);
      // find action in spaceActions and remove it
      // setSpaceActions((prevActions) =>
      //   prevActions.filter((a) => a.name !== action.name)
      // );
      //setSpaceAction(action);
    });

    connection.on("ReceiveCollections", (resources) => {
      console.log("ReceiveCollections", resources);
      setCollections(resources);
    });
    connection.on("ReceiveUsers", (new_users) => {
      console.log("ReceiveUsers", new_users);
      var existing_users = [...usersRef.current]; // make a copy of _prevUsers
      // append new users to existing users up front, if user already exists move it on top
      new_users.forEach((user) => {
        const existing_userIndex = existing_users.findIndex(
          (u) => u.address === user.address && user.name === u.name
        );
        if (existing_userIndex !== -1) {
          //console.log("existing_user", existing_userIndex);
          existing_users.splice(existing_userIndex, 1);
        } else {
          //console.log("new_user", user);
        }
        existing_users = [user, ...existing_users];
      });
      setUsers(existing_users);
    });
    connection.on("ReceiveProjects", (projects) => {
      console.log("ReceiveProjects", projects);
      setUserProjects(projects);
    });
    connection.on("ReceiveProject", (project) => {
      console.log("ReceiveProject", project);
      setUserProject(project);
      const _userProjects = projectsRef.current;
      // update project in userProjects
      //var _userProjects = [...userProjects];
      var projectIndex = _userProjects.findIndex(
        (p) => p.name === project.name
      );
      if (projectIndex !== -1) {
        _userProjects[projectIndex] = project;
        setUserProjects(_userProjects);
      }
    });
    connection.on("ReceiveNodeInfo", (fromNodeId, connectionId, nodes) => {
      console.log("ReceiveNodeInfo", fromNodeId, connectionId, nodes);
      // got throuh all nodes and check if nodeId is not in spaceNodes, add it
      var all_nodes = [];
      if (nodes === undefined) return;
      nodes.forEach((node) => {
        if (
          !spaceNodes.some(
            (n) =>
              n.nodeId === node.nodeId && n.connectionId === node.connectionId
          )
        ) {
          all_nodes.push(node);
        }
      });
      setSpaceNodes((prevNodes) => [...prevNodes, ...all_nodes]);
      console.log("ReceiveNodeInfo", spaceNodes);
    });
    connection.on("NodeDisconnected", (nodeId, nodeConnectionId) => {
      // debugger;
      const findNode = spaceNodes.find((node) => node.nodeId === nodeId);
      console.log(
        "NodeDisconnected",
        nodeId,
        nodeConnectionId,
        spaceNodes,
        findNode,
        users
      );

      // remove users from that are have nodeId
      if (findNode !== undefined) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.nodeId !== nodeId)
        );
      }
      setSpaceNodes((prevNodes) =>
        prevNodes.filter((node) => node.connectionId !== nodeConnectionId)
      );
    });
    connection.on(
      "ReceiveQueryNodeUsers",
      (fromNodeId, connectionId, nodeUsers) => {
        console.log(
          "ReceiveQueryNodeUsers",
          fromNodeId,
          connectionId,
          nodeUsers
        );

        const newUsers = nodeUsers.filter(
          (nodeUser) =>
            !users.some(
              (user) =>
                user.address === nodeUser.address &&
                user.nodeId === nodeUser.nodeId
            )
        );

        setUsers((prevUsers) => {
          const combinedUsers = [...prevUsers, ...newUsers];
          const uniqueUsers = Array.from(
            new Set(combinedUsers.map((user) => JSON.stringify(user)))
          ).map((user) => JSON.parse(user));
          return uniqueUsers;
        });
      }
    );
    connection.on("ReceiveSearchResultsUser", (foundSpaceUser) => {
      console.log("ReceiveSearchResultsUser", foundSpaceUser);
      // spaceUsersRef
      // console.log("spaceUsersRef", spaceUsersRef.current);
      // similar to receiveNodeInfo but for searchResults
      var _prevSpaceUsers = spaceUsersRef.current;
      // find if user already exists in spaceUsers
      let suser = _prevSpaceUsers.find(
        (u) => u.user.address === foundSpaceUser.user.address
      );

      if (suser !== undefined) {
        // update contents of spaceUser
        if (foundSpaceUser.contents.length > 0)
          suser.contents = [...foundSpaceUser.contents];
        if (foundSpaceUser.collections.length > 0)
          suser.collections = [...foundSpaceUser.collections];
        if (foundSpaceUser.manifests.length > 0)
          suser.manifests = [...foundSpaceUser.manifests];

        // remove user from spaceUsers
        setSpaceUsers((prevSpaceUsers) =>
          prevSpaceUsers.filter(
            (u) => u.user.address !== foundSpaceUser.user.address
          )
        );
        // add updated spaceUser to spaceUsers
        setSpaceUsers((prevSpaceUsers) => [suser, ...prevSpaceUsers]);
      } else {
        // add new spaceUser to spaceUsers
        setSpaceUsers((prevSpaceUsers) => [foundSpaceUser, ...prevSpaceUsers]);
      }
    });
    connection.on("ReceiveSearchResults", (searchResults) => {
      console.log("ReceiveSearchResults", searchResults);
    });
    connection.on("SearchingComplete", (query) => {
      console.log("SearchingComplete", query);
      setIsSearchingActive(false);
    });
    connection.on("TaskComplete", (task) => {
      console.log("TaskComplete", task);
      setCompletedTask(task);
    });
    // get actions nodes you are connected to can perform
    connection.on("ReceiveActions", (fromNodeId, forConnectionId, actions) => {
      console.log("ReceiveActions", actions);
      const nodeActions = nodeActionRef.current;
      const newActions = actions.filter(
        (action) =>
          !nodeActions.some(
            (a) =>
              a.name === action.name && a.provideNodeId === action.provideNodeId
          )
      );
      setNodeActions((prevActions) => [...prevActions, ...newActions]);
    });
    // ReceiveProcessingQueueInfo
    connection.on("ReceiveProcessingQueueInfo", (info) => {
      console.log("ReceiveProcessingQueueInfo", info);
      setProcessingQueueInfo(info);
    });

    connection.on(
      "ReceiveCharacters",
      (fromNodeId, forConnectionId, characters) => {
        console.log("ReceiveCharacters", characters);
        const nodeCharacters = nodeCharacterRef.current;
        const newCharacters = characters.filter(
          (character) =>
            !nodeCharacters.some(
              (c) =>
                c.name === character.name &&
                c.provideNodeId === character.provideNodeId
            )
        );
        setNodeCharacters((prevCharacters) => [
          ...prevCharacters,
          ...newCharacters,
        ]);
      }
    );

    return () => {
      connection.stop();
      setHub(undefined);
      setConnected(false);
    };
  }, []);

  useEffect(() => {
    console.log("networkStatus", networkStatus);
  }, [networkStatus]);

  useEffect(() => {
    console.log("spaceActions", spaceActions);
  }, [spaceActions]);

  useEffect(() => {
    if (address === "") return;
    if (hub !== undefined) {
      refreshEthBalance();
    } else {
      toast("Hub is not connected address: " + address);
      //console.log("Hub is not connected");
    }
  }, [address]);

  useEffect(() => {
    spaceActionsRef.current = spaceActions;
    //console.log("hook spaceActions", spaceActions);
  }, [spaceActions]);

  useEffect(() => {
    usersRef.current = users;
    //console.log("hook users", users);
  }, [users]);

  useEffect(() => {
    nodeActionRef.current = nodeActions;
    //console.log("hook nodeActions", nodeActions);
  }, [nodeActions]);

  useEffect(() => {
    nodeCharacterRef.current = nodeCharacters;
    //console.log("hook nodeCharacters", nodeCharacters);
  }, [nodeCharacters]);

  useEffect(() => {
    //console.log("isSearchingActive", isSearchingActive);
    isSearchingActiveRef.current = isSearchingActive;
  }, [isSearchingActive]);

  useEffect(() => {
    //console.log("hook spaceUsers", spaceUsers);
    spaceUsersRef.current = spaceUsers;
  }, [spaceUsers]);

  useEffect(() => {
    //console.log("projects", userProjects);
    projectsRef.current = userProjects;
  }, [userProjects]);

  useEffect(() => {
    if (serviceInfo === undefined) return;
    //console.log("hook ServiceInfo", serviceInfo);
  }, [serviceInfo]);
  useEffect(() => {
    if (tokenContract === undefined) return;
    //console.log("hook TokenContract", tokenContract);
  }, [tokenContract]);
  useEffect(() => {
    if (serviceContract === undefined) return;
    //console.log("hook ServiceContract", serviceContract);
  }, [serviceContract]);
  useEffect(() => {
    if (nodeHub === undefined) return;
    //console.log("hook nodeHub+hub", nodeHub, hub);
  }, [hub, nodeHub]);

  useEffect(() => {
    if (web3 === undefined) return;
    if (nodeTokenAddress === null) return;
    if (nodeServiceAddress === null) return;
    if (nodeHub === null) return;

    const _tokenContract = new web3.eth.Contract(tokenABI, nodeTokenAddress);
    const _serviceContract = new web3.eth.Contract(
      serviceABI,
      nodeServiceAddress
    );

    setTokenContract(_tokenContract);
    setServiceContract(_serviceContract);

    //console.log("Token Contract", nodeTokenAddress);
    //console.log("Service Contract", nodeTokenAddress);
  }, [nodeHub, nodeTokenAddress, nodeServiceAddress, web3]);

  useEffect(() => {
    //console.log("hook userContent", userContent);
  }, [userContent]);
  useEffect(() => {
    //console.log("hook collection", collection);
  }, [collection]);

  // when all is loaded call connectWalletHandler
  useEffect(() => {
    if (window.ethereum && web3 === undefined) {
      // deep linking to wallet
      // https://metamask.app.link/dapp/spaces.datafund.io

      var _web3 = new Web3(window.ethereum);
      setWeb3(_web3);
      // connectMetamaskMobile
      //console.log("setting web3", _web3);

      window.ethereum.on("accountsChanged", function (accounts) {
        console.log("Account changed to", accounts[0]);
        setConnected(false);
      });
      window.ethereum.on("chainChanged", function (chainId) {
        console.log("Chain changed to", chainId);

        setConnected(false);
        currentChainId = parseInt(chainId, 16);
        window.location.reload();
      });
    }

    if (web3 === undefined) return;
    //console.log("web3", web3);

    // console.log("web3", web3);
    // window.ethereum.on("accountsChanged", function (accounts) {
    //   console.log("Account changed to", accounts[0]);
    //   setConnected(false);
    // });
    // window.ethereum.on("chainChanged", function (chainId) {
    //   console.log("Chain changed to", chainId);

    //   setConnected(false);
    //   currentChainId = parseInt(chainId, 16);
    //   window.location.reload();
    // });
  }, [web3]);

  const connectWalletHandler = async () => {
    // if (isMobileBrowser()) {
    //   connectMetamaskMobile();
    //   return;
    // }
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        console.log("Account changed to", accounts[0]); // accounts[0] is the currently selected account
        //window.location.reload();
        setConnected(false); // Here you can add code to handle the account change
      });
      window.ethereum.on("chainChanged", function (chainId) {
        console.log("Chain changed to", chainId);
        setConnected(false);
        currentChainId = parseInt(chainId, 16); // parse from 0x to string

        window.location.reload(); // recommend reloading the page unless you have good reason not to.
      });
      // check that chainid is correct, returned chainId is in hexadecimal, convert to decimal
      currentChainId = parseInt(
        await window.ethereum.request({ method: "eth_chainId" }),
        16
      );

      if (currentChainId !== supportedChainId) {
        addNetworkToMetamask();
        alert(
          "Please switch to supported network: " +
            supportedChainId +
            " currently on: " +
            currentChainId
        );
        return;
      }

      const checkAccounts = await web3.eth.getAccounts();
      if (checkAccounts.length === 0) {
        try {
          const requestAccounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
        } catch (error) {
          toast.error("User denied account access");
        }
      }

      try {
        setIsPaymentOpen(false);
        setIsDownloadOpen(false);

        const accounts = await web3.eth.getAccounts();
        if (accounts.length === 0) {
          toast.warn(
            "No accounts found. Make sure MetaMask is unlocked and connected."
          );

          return;
        }

        const userAddress = accounts[0]; // The first account in MetaMask
        await setAddress(userAddress);

        // check local storage if same user and signature
        const storedUser = localStorage.getItem("user");
        const storedSignature = localStorage.getItem("signature");
        const storedSignMessage = localStorage.getItem("signMessage");
        console.log("Stored user", storedUser);

        if (storedUser === userAddress) {
          hub.invoke(
            "VerifySignature",
            userAddress,
            storedSignMessage,
            storedSignature
          );
        } else {
          const signMessage =
            "You are signing to prove your address. " +
            web3.utils.keccak256(Date.now() + " " + userAddress);
          // get users signature
          const signature = await web3.eth.personal.sign(
            signMessage,
            userAddress,
            ""
          );

          localStorage.setItem("user", userAddress);
          localStorage.setItem("signature", signature);
          localStorage.setItem("signMessage", signMessage);

          hub.invoke("VerifySignature", userAddress, signMessage, signature);
        }
        //console.log("Signature", signature);

        /*
        const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
        const serviceContract = new web3.eth.Contract(
          serviceABI,
          serviceAddress
        );
        // check ETH balance

        setTokenContract(tokenContract);
        setServiceContract(serviceContract); */

        setConnected(true);

        getUserData();
        refreshTokenBalance();
        refreshEthBalance();

        searchUsers("");
        getQueryNodes();
        getNodeActions("");
        getNodeCharacters("");

        if (resourceToDownload !== null) receiveResource(resourceToDownload);
      } catch (err) {
        toast(err);
      }
    } else {
      if (isMobileBrowser()) {
        connectMetamaskMobile();
        alert("No wallet found on mobile, can not connect!");
        return;
      } else {
        alert("Please install MetaMask to connect!");
      }
    }
  };
  const addNetworkToMetamask = () => {
    if (isMobileBrowser() && !window.ethereum) {
      connectMetamaskMobile();
      alert("Mobile browser without wallet!");
      return;
    }
    if (!window.ethereum) {
      alert("Can not switch network. Please install MetaMask!");
      return;
    }

    window.ethereum
      .request({
        method: "wallet_addEthereumChain",
        params: [
          {
            //chainId: network.chainId,
            chainId: `0x${parseInt(network.chainId).toString(16)}`,
            rpcUrls: [network.rpc],
            chainName: network.name,
            nativeCurrency: {
              name: network.currencyName,
              symbol: network.symbol,
              decimals: network.decimals,
            },
            blockExplorerUrls: [network.blockExplorer],
          },
        ],
      })
      .then((res) => {
        // toast("Network added to MetaMask");
        // console.log("Network added to MetaMask", res);
        // currentChainId = network.chainId;
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async function refreshEthBalance() {
    if (web3 !== undefined && address !== "") {
      console.log("ETH Balance from", address);
      const ethBalance = await web3.eth.getBalance(address);
      setBalance(ethBalance);
      console.log("ETH Balance", ethBalance);
    }
  }
  async function getUserData() {
    if (hub !== undefined) {
      try {
        await hub.invoke("GetUserData");
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected GetUserData");
  }
  async function refreshTokenBalance() {
    if (hub !== undefined) {
      try {
        await hub.invoke("GetTokenBalance");
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected balance");
  }
  async function uploadProfileSettings(username, userinfo, userimage, misc) {
    if (hub !== undefined) {
      try {
        await hub.invoke(
          "UploadProfileSettings",
          username,
          userinfo,
          userimage,
          misc
        );
        console.log("uploadSettings", username, userinfo, userimage, misc);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected uploadProfileSettings");
  }

  async function receiveResource(hash) {
    if (hub !== undefined) {
      try {
        await hub.invoke("ReceiveResource", hash);
        console.log("ReceiveResource sent", hash);
      } catch (err) {
        console.error(err);
      }
    } //else toast("Hub is not connected receiveResource");
  }
  async function getUsers(address) {
    if (hub !== undefined) {
      try {
        await hub.invoke("ListUsers", address);
        console.log("ListUsers sent");
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected getUsers");
  }
  async function searchUsers(query) {
    //sendToNetwork();
    if (hub !== undefined) {
      try {
        // setUsers([]);
        // setSpaceUsers([]);
        setIsSearchingActive(true);
        await hub.invoke("SearchUsers", query);
        await hub.invoke("QueryNodeUsers", query);
        await hub.invoke("SearchAllSpacesInNode", query);

        console.log("SearchUsers sent");
        //setSelectedUser(null);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected searchUsers");
  }
  async function SearchAllSpacesInNode(query) {
    if (hub !== undefined) {
      try {
        //setUsers([]);
        await hub.invoke("SearchAllSpacesInNode", query);
        console.log("SearchAllSpacesInNode sent");
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected SearchAllSpacesInNode");
  }
  async function getNodeProcessQueue(query) {
    if (hub !== undefined) {
      try {
        await hub.invoke("Node_GetProcessingQueueInfo", query);
        console.log("Node_GetProcessingQueueInfo sent");
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected GetProcessingQueueInfo");
  }
  async function getNodeActions(query) {
    if (hub !== undefined) {
      try {
        await hub.invoke("Node_GetActions", query);
        console.log("Node_GetActions sent");
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected GetNodeActions");
  }
  async function getNodeCharacters(query) {
    if (hub !== undefined) {
      try {
        await hub.invoke("Node_GetCharacters", query);
        console.log("Node_GetCharacters sent");
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected GetNodeCharacters");
  }

  async function getWhatDataIsRequired(spaceAction) {
    if (hub !== undefined) {
      try {
        // await hub.invoke(
        //   "Node_WhatDataIsRequired",
        //   "Data Requirements - Information on what data is required for tasks",
        //   spaceAction
        // );
        var newSpaceAction = {
          name: "What",
          project: "Information On Data Requirements",
          // action: "What",
          resource: address + "/" + spaceAction.name,
          providerNodeId: spaceAction.providerNodeId,
          owner: "What/What",
        };

        await invokeNodeAction(
          { name: newSpaceAction.project },
          newSpaceAction,
          address + "/" + spaceAction.name
        );
        console.log("Node_WhatDataIsRequired sent", spaceAction);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected getWhatDataIsRequired");
  }

  async function invokeNodeAction(project, action, resource) {
    if (hub !== undefined) {
      try {
        action.project = project.name;
        action.resource = resource;
        action.time = Date.now();
        // var newClientAction = {
        //   project: project,
        //   action: action,
        //   resource: resource,
        // };
        console.log("Node_InvokeAction sent", action);
        // add action to spaceActions
        setSpaceActions((prevActions) => [action, ...prevActions]);
        // setSpaceAction({
        //   project: project,
        //   action: action,
        //   resource: resource,
        // });

        await hub.invoke("Node_InvokeAction", project.name, action, resource);

        var counter = parseInt(localStorage.getItem(action.name)) || 0;
        counter++;
        localStorage.setItem(action.name, counter);
        console.log("counter", action.name, counter);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected invokeNodeAction");
  }

  async function invokeAskCharacter(projectName, character, resource, content) {
    if (hub !== undefined) {
      try {
        // console.log(
        //   "Node_AskCharacter sent",
        //   projectName,
        //   character,
        //   resource,
        //   content
        // );
        await hub.invoke(
          "Node_AskCharacter",
          projectName,
          character,
          resource,
          content
        );
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected invokeAskCharacter");
  }

  async function onSelectCharacter(character) {
    console.log(
      "onSelectCharacter",
      character,
      viewCharacterPanel,
      resourceContents
    );
    toast(
      <>
        <CharacterCard key={"index"} {...character} size />
        <button
          onClick={() => {
            invokeAskCharacter(
              character.codename,
              character,
              viewCharacterPanel,
              resourceContents.data
            );
            toast.dismiss();
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;Ask&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </button>
      </>
    );
  }

  async function createUserFolder(folderName) {
    console.log("createUserFolder", folderName);
    hub.invoke("CreateFolder", folderName);
  }
  async function addFileToFolder(
    folderName,
    filename,
    content,
    update = false
  ) {
    console.log("AddFileToFolder", folderName, filename, content, update);
    hub.invoke("AddFileToFolder", folderName, filename, content, update);
  }
  async function renameFileInFolder(projectName, resourceName, newFilename) {
    console.log("RenameFileInFolder", projectName, resourceName, newFilename);
    hub.invoke("RenameFileInFolder", projectName, resourceName, newFilename);
  }
  async function onDragFileToFolder(file, folder) {
    console.log("onDragFileToFolder", file, folder);
    hub.invoke("MoveFileToFolder", file, folder);
    //addFileToFolder(folderName, file.name, file.content);
  }
  async function shareProjectWith(projectName, userAddress) {
    console.log("ShareProjectWith", projectName, userAddress);
    hub.invoke("ShareProjectWith", projectName.name, userAddress);
  }
  async function unshareProjectWith(projectName, userAddress) {
    console.log("UnshareProjectWith", projectName, userAddress);
    hub.invoke("UnshareProjectWith", projectName.name, userAddress);
  }

  async function MergeFilesTogether(projectName, resourcesList) {
    var resResources = resourcesList.map((r) => r.res);
    console.log("MergeFilesTogether", projectName, resResources);
    hub.invoke("MergeFilesTogether", projectName, resResources);
  }
  async function PolinateResource(resource) {
    console.log("PolinateResource", resource);
    hub.invoke("PolinateResource", resource);
  }
  async function SendResourceToAddress(resource, address) {
    console.log("SendResourceToAddress", resource, address);
    hub.invoke("SendResource", resource, address);
  }

  async function CopyFileToContents(resource) {
    console.log("CopyFileToContents", resource);
    hub.invoke("CopyFileToContents", resource);
  }
  async function getDataLineage(resource) {
    console.log("getDataLineage", resource);
    hub.invoke("GetDataLineage", resource);
  }
  async function getDataProvenance(resource) {
    console.log("getDataProvenance", resource);
    hub.invoke("GetDataProvenance", resource);
  }

  async function changeProjectVisibility(address, projectName, visibility) {
    console.log("changeProjectVisibility", address, projectName, visibility);
    hub.invoke("SetProjectPublic", address, projectName, visibility);
  }
  async function getDataLineage(resource) {
    console.log("getDataLineage", resource);
    hub.invoke("GetDataLineage", resource);
  }
  async function getDataProvenance(resource) {
    console.log("getDataProvenance", resource);
    hub.invoke("GetDataProvenance", resource);
  }
  async function getResourceContents(resource) {
    console.log("GetResourceContents", resource);
    hub.invoke("GetResourceContents", resource);
  }

  async function getUserContents(user) {
    if (user.nodeId !== serviceInfo.nodeClient?.nodeId) {
      toast(
        <>
          User is not on node you are connected to. Data might not be available.
          <hr />
          Switch to node id ? <br />
          <strong>{user.nodeId}</strong>
        </>
      );
      return;
      // TODO, getNetworkUserContents(user);
    }
    if (hub !== undefined) {
      try {
        //setUserContents([]);
        setUserAddress(user.address);
        await hub.invoke("GetUserContents", user.address);
        console.log("GetUserContents sent", user.address);
      } catch (err) {
        console.error(err);
      }
    }
  }
  async function getUserContent(address, contentName) {
    if (hub !== undefined) {
      try {
        await hub.invoke("GetUserContent", address, contentName);
        console.log("GetUserContent sent", address, contentName);
      } catch (err) {
        console.error(err);
      }
    }
  }
  async function getUserCollections(user) {
    if (hub !== undefined) {
      try {
        //setUserContents([]);
        //setUserCollection()
        await hub.invoke("GetUserCollections", user.address);
        console.log("GetUserCollections sent", user.address);
      } catch (err) {
        console.error(err);
      }
    }
  }
  async function getUserCollection(address, collectionName) {
    if (hub !== undefined) {
      try {
        setCollection(null);
        await hub.invoke("GetUserCollection", address, collectionName);
        console.log("GetUserCollection sent", address, collectionName);
      } catch (err) {
        console.error(err);
      }
    }
  }
  async function getManifests(address) {
    if (hub !== undefined) {
      try {
        //setManifests([]);
        console.log("ListManifests sent");
        await hub.invoke("ListManifests", address);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected getManifests");
  }
  async function deleteManifest(resource) {
    if (hub !== undefined) {
      try {
        //setManifests([]);
        console.log("deleteManifest sent");
        await hub.invoke("DeleteManifest", resource);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected deleteManifest");
  }
  async function deleteCollection(collection) {
    if (hub !== undefined) {
      try {
        console.log("deleteCollection sent", collection);
        await hub.invoke("DeleteCollection", collection);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected DeleteCollection");
  }
  async function deleteContents(contentsName) {
    if (hub !== undefined) {
      try {
        console.log("deleteContents sent");
        await hub.invoke("DeleteContents", contentsName);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected deleteContents");
  }
  async function deleteUserProject(project) {
    if (hub !== undefined) {
      try {
        console.log("DeleteProject sent", project);
        await hub.invoke("DeleteProject", project.name);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected DeleteProject");
  }
  async function removeFileFromFolder(filename) {
    if (hub !== undefined) {
      try {
        console.log("RemoveFileFromFolder sent", filename);
        await hub.invoke("RemoveFileFromFolder", filename);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected DeleteFileFromFolder");
  }
  async function getQueryNodes() {
    if (hub !== undefined) {
      try {
        console.log("QueryNodes sent");
        await hub.invoke("QueryNodes", "");
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected getQueryNodes");
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async function invokeGetUserProjects(address) {
    if (hub !== undefined) {
      try {
        console.log("GetUserProjects sent", address);
        await hub.invoke("GetUserProjects", address);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected GetUserProjects");
  }
  async function getUserProjects(address) {
    setUserProject(null);

    invokeGetUserProjects(address);
    /*
    // fetch  'https://relay/Projects?address=0xdaa070D909E010211606144eDe5B2ca6864C2c1c'
    var post_server = DATA_RELAY_HUB_URL.replace("dataRelayHub", "");
    var _userProjects = await fetch(
      post_server + "Projects?address=" + address
    );
    const userProjects = await _userProjects.json();
    setUserProjects(userProjects);

    console.log("getUserProjects", userProjects);
    */
  }
  async function getUserProject(address, project) {
    if (hub !== undefined) {
      try {
        console.log("getUserProject sent", address, project);
        await hub.invoke("GetUserProject", address, project);
      } catch (err) {
        console.error(err);
      }
    } else toast("Hub is not connected getUserProject");

    /*
    console.log("getUserProject", address, project);
    var post_server = DATA_RELAY_HUB_URL.replace("dataRelayHub", "");
    var _userProject = await fetch(
      post_server + "GetProject?address=" + address + "&projectName=" + project
    );

    const projectData = await _userProject.json();
    setUserProject(projectData);
    console.log("getUserProject", projectData); */
  }
  // async function deleteUserProject(address, project) {
  //   var post_server = DATA_RELAY_HUB_URL.replace("dataRelayHub", "");
  // }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async function sendToNetwork() {
    console.log("sendToNetwork sent");
    //await hub.invoke("Send_ToNetwork", null, null, "test", null);
  }

  // function to keep alive the connection
  const keepAlive = useCallback(async () => {
    if (hub !== undefined) {
      try {
        await hub.invoke("KeepAlive");
      } catch (err) {
        console.error(err);
        if (connected) toast("Hub is not connected, refresh");

        setConnected(false);
        setConnectionId("");
      }
    } else {
      if (connected) toast("No longer connected");
    }
  }, [connected, hub]);

  // ad function that does keep alive every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      keepAlive();
    }, 30000);
    return () => clearInterval(interval);
  }, [keepAlive]);
  const sendPaymentConfirmation = async (
    address,
    forOwner,
    transactionHash
  ) => {
    setPaymentTxHash(transactionHash);
    try {
      console.log(
        "PaymentConfirmation",
        address,
        transactionHash,
        paymentResource,
        paymentFilename,
        paymentPrice,
        paymentSize
      );
      await hub.invoke(
        "PaymentConfirmation",
        address,
        paymentResource,
        paymentFilename,
        paymentPrice,
        paymentSize,
        forOwner,
        transactionHash
      );
      //console.log("Payment confirmation sent");
    } catch (err) {
      console.error(err);
    }
  };
  const sendStoragePaymentConfirmation = async (
    address,
    forOwner,
    transactionHash
  ) => {
    setPaymentTxHash(transactionHash);
    try {
      console.log(
        "StoragePaymentConfirmation",
        address,
        transactionHash,
        paymentResource,
        paymentFilename,
        paymentPrice,
        paymentSize
      );
      await hub.invoke(
        "StoragePaymentConfirmation",
        address,
        paymentResource,
        paymentFilename,
        paymentPrice,
        paymentSize,
        forOwner,
        transactionHash
      );
      //console.log("Payment confirmation sent");
    } catch (err) {
      console.error(err);
    }
  };
  async function paymentRequest(
    resource,
    filename,
    price,
    size,
    dataowner,
    beneficiary,
    consumedInBytes
  ) {
    setIsPaymentOpen(true);
    setPaymentResource(resource);
    setPaymentFilename(filename);
    setPaymentPrice(price.toString());
    setPaymentSize(size.toString());
    setDataowner(dataowner);
    setBeneficiary(beneficiary);
    setStorageConsumed(consumedInBytes);
  }
  async function handlePayment(resource, amountNumberString) {
    let num = Number(amountNumberString);
    var fAmount = num.toFixed(18);

    const amountToSend = web3.utils.toWei(fAmount, "ether");
    // send eth
    console.log("paying for resource download", resource, amountToSend);
    const confirmationId = "0x" + resource;
    serviceContract.methods
      //.payForDownloadInEth(confirmationId, amountToSend)
      .payForDownloadForOwnerInEth(confirmationId, dataowner, amountToSend)
      .send({ from: address, value: amountToSend })
      .on("transactionHash", function (hash) {
        console.log("transactionHash", hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        toast("Transaction receipt: " + receipt.transactionHash);
        sendPaymentConfirmation(address, dataowner, receipt.transactionHash);
        setIsPaymentOpen(false);
      })
      .on("error", function (error, receipt) {
        console.log("error", error);
        toast.error("Error" + error);
      })
      .catch(function (error) {
        console.error("Transaction failed", error);
        toast.error("Transaction Failed" + error);
      });
  }
  async function handleStoragePayment(resource, amountNumberString) {
    //var fAmount = parseFloat(amountNumberString);
    let num = Number(amountNumberString);
    var fAmount = num.toFixed(18);
    const amountToSend = web3.utils.toWei(fAmount, "ether");
    // send eth
    console.log("paying for resource upload", resource, amountToSend);
    const confirmationId = "0x" + resource;
    serviceContract.methods
      //.payForDownloadInEth(confirmationId, amountToSend)
      .payForDownloadForOwnerInEth(confirmationId, beneficiary, amountToSend)
      .send({ from: address, value: amountToSend })
      .on("transactionHash", function (hash) {
        console.log("transactionHash", hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        toast("Transaction receipt: " + receipt.transactionHash);
        sendStoragePaymentConfirmation(
          address,
          beneficiary,
          receipt.transactionHash
        );
        setIsPaymentOpen(false);
      })
      .on("error", function (error, receipt) {
        console.log("error", error);
        toast.error("Error" + error);
      })
      .catch(function (error) {
        console.error("Transaction failed", error);
        toast.error("Transaction Failed" + error);
      });
  }
  async function handleOutstandingPayment(paymentId, resource) {
    console.log("handleOutstandingPayment", paymentId, resource);
    // if (hub !== undefined) {
    //   try {
    //     await hub.invoke("ReceiveOutstandingPayment", paymentId, resource);
    //     console.log("ReceiveOutstandingPayment sent");
    //   } catch (err) {
    //     console.error(err);
    //   }
    // } else toast("Hub is not connected onOutstandingPayment");
  }

  async function getReceiptToPayForStorage(sizeInMb) {
    if (hub !== undefined) {
      try {
        // convert to number
        sizeInMb = parseFloat(sizeInMb);
        await hub.invoke("GetReceiptToPayForStorage", sizeInMb);
        console.log("GetReceiptToPayForStorage sent");
      } catch (err) {
        console.error(err);
      }
    }
  }
  async function handlePayForStoragePayment(paymentId, amountNumberString) {
    //var fAmount = parseFloat(amountNumberString);
    let num = Number(amountNumberString);
    var fAmount = num.toFixed(18);
    const amountToSend = web3.utils.toWei(fAmount, "ether");
    const confirmationId = "0x" + paymentId;
    console.log("handleBuyStoragePayment", paymentId, amountToSend);
    serviceContract.methods
      //.payForDownloadInEth(confirmationId, amountToSend)
      .payForDownloadForOwnerInEth(confirmationId, dataowner, amountToSend)
      .send({ from: address, value: amountToSend })
      .on("transactionHash", function (hash) {
        console.log("transactionHash", hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        toast("Transaction receipt: " + receipt.transactionHash);
        sendPayForStorageConfirmation(paymentId, receipt.transactionHash);
        setIsPaymentOpen(false);
      })
      .on("error", function (error, receipt) {
        console.log("error", error);
        toast.error("Error" + error);
      })
      .catch(function (error) {
        console.error("Transaction failed", error);
        toast.error("Transaction Failed" + error);
      });
  }
  const sendPayForStorageConfirmation = async (
    confirmationId,
    transactionHash
  ) => {
    setPaymentTxHash(transactionHash);
    try {
      console.log("PayForStorageConfirmation", confirmationId, transactionHash);
      await hub.invoke(
        "PayForStorageConfirmation",
        confirmationId,
        transactionHash
      );
      //console.log("Payment confirmation sent");
    } catch (err) {
      console.error(err);
    }
  };

  // Assuming connection is already started and is an instance of HubConnection
  async function receiveData(
    connection,
    paymentConfirmationId,
    fileName,
    price,
    size
  ) {
    if (!connection) {
      toast("Hub is not connected in receiveData");
      return;
    }
    const parts = []; // To hold the received Base64 chunks
    var downloaded = 0;
    var totalSize = parseInt(size) + 1;
    setIsDownloadOpen(true);
    const startTime = new Date().getTime();

    try {
      var itemCount = 0;
      connection.stream("StreamFile", paymentConfirmationId).subscribe({
        next: (item) => {
          //console.log("item:" + item);
          const b = atob(item); // push bytes to parts (incoming item is base64)
          //const b = Buffer.from(item, "base64").toString("binary");
          parts.push(b);
          downloaded += b.length;
          setDownloadedSize(downloaded);
          var percent = (downloaded / totalSize) * 100;
          // console.log(
          //   "packet:" + b.length,
          //   "downloaded:" + downloaded,
          //   "totalSize:" + totalSize,
          //   "%" + percent
          // );
          setDownloadPercentage(percent);
          const elapsed = new Date().getTime() - startTime;
          setDownloadTime(elapsed);
          setStreamStatus("Received " + formatBytes(downloaded));
        },
        complete: () => {
          setStreamStatus("File received");
          const bytes = parts.join("");
          const length = bytes.length;
          const byteNumbers = new Uint8Array(length); // Combine all parts into a single uint8array
          for (let i = 0; i < length; i++) {
            byteNumbers[i] = bytes.charCodeAt(i);
          }
          const blob = new Blob([byteNumbers], {
            type: "application/octet-stream",
          });

          downloadBlob(blob, fileName); // Trigger download
          setStreamStatus("Download complete");
          setDownloadPercentage(100);
          refreshEthBalance();
        },
        error: (err) => {
          console.error(err);
          setDownloadPercentage(100); // to display close button on modal
          setStreamStatus("Error:" + JSON.stringify(err));
        },
      });
    } catch (e) {
      console.error(e);
    }
  }
  function downloadBlob(blob, filename) {
    // Create an invisible anchor element
    const anchor = document.createElement("a");
    const url = window.URL.createObjectURL(blob);

    anchor.href = url;
    anchor.download = filename;
    anchor.style.display = "none"; // Make the anchor element invisible

    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  }

  const head = (
    <>
      {!connected && (
        <header className="App-header">
          {/* background image to banner.png */}
          {/* <img src="banner.png" className="App-logo" alt="logo" /> */}

          {/* <h1>Data Relay Service</h1> */}
          <h3 style={{ marginBottom: "0px" }}>Store • Process • Monetize</h3>
          <h1>{APPNAME}</h1>

          <span
            className="glitch2"
            style={{
              fontSize: "25em",
              fontWeight: "19000",
              position: "fixed",
              opacity: 0.01,
              zIndex: -1,
              left: "2%",
              top: "13%",
            }}
          >
            {APPNAME}
          </span>
          <span
            className="glitch2"
            style={{
              fontSize: "25em",
              fontWeight: "19000",
              position: "fixed",
              opacity: 0.01,
              zIndex: -1,
              left: "0%",
              top: "50%",
            }}
          >
            # # # # #
          </span>
        </header>
      )}
    </>
  );

  // if (hub === undefined) return <div>{head} Connecting...</div>;

  if (!isAgreed)
    return (
      <div className="App">
        <Terms onIsAgreed={setIsAgreed} token={assetName} price={0.001} />
      </div>
    );

  //console.log("spaceNodes", spaceNodes);

  return (
    <div className="App">
      <img src="market-spaces.webp" className="Spaces-logo" alt="logo" />
      {head}
      <>
        {/* <CytoGraph nodes={spaceNodes} edges={null} /> */}
        {hub === undefined && (
          <>
            <h3>Connection status: {networkStatus}</h3>
            <button onClick={(e) => window.location.reload()}>Reload</button>
          </>
        )}
        {!connected && hub !== undefined && (
          <>
            {/* <h2>Welcome </h2> */}
            {currentChainId === supportedChainId && (
              <button onClick={connectWalletHandler}>Connect</button>
            )}
            {currentChainId !== supportedChainId && (
              <div>
                <br />
                Chain ID - Network not supported. <br />
                <button onClick={() => addNetworkToMetamask()}>
                  Switch to {network.name}
                </button>
              </div>
            )}
          </>
        )}

        {connected === true && (
          <>
            <UserMenu
              web3={web3}
              balance={balance}
              tokenBalance={tokenBalance}
              assetName={assetName}
              address={address}
              refreshTokenBalance={refreshTokenBalance}
              onUploadProfileSettings={uploadProfileSettings}
              onSearchUsers={searchUsers}
              hub={hub}
              userData={currentUserData}
              onGetUserData={getUserData}
              formatBytes={formatBytes}
              onBuyStorage={getReceiptToPayForStorage}
              searchDisabled={isSearchingActive}
              onFolderUploadFinished={invokeGetUserProjects}
              onDisplaySwarmHashDownload={setIsDownloaderVisible}
            />

            {isDownloaderVisible === true && (
              <Modal
                isOpen={isDownloaderVisible}
                showClose
                onClose={() => setIsDownloaderVisible(false)}
              >
                {/* <div className="downloader"> */}
                <h2>Download from Swarm</h2>

                <input
                  type="text"
                  id="hash"
                  placeholder="Enter resource identifier"
                  value={hash}
                  style={{ width: "80%", textAlign: "center" }}
                  onChange={(e) => setHash(e.target.value)}
                />
                <br />
                <span> 0xAdress/bzzHash No 0x</span>
                <br />
                <button onClick={() => receiveResource(hash)}>Download</button>

                {/* </div> */}
              </Modal>
            )}
            <>
              <UserTree
                nodeId={serviceInfo.nodeClient?.nodeId}
                address={address}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                users={users}
                onGetUsers={getUsers}
                userContents={userContents}
                userContent={userContent}
                onGetUserContents={getUserContents}
                onGetUserContent={getUserContent}
                onDeleteContents={deleteContents}
                onGetProjects={getUserProjects}
                onGetProject={getUserProject}
                onSetProject={setUserProject}
                onDeleteProject={deleteUserProject}
                onDeleteFile={removeFileFromFolder}
                userProjects={userProjects}
                userProject={userProject}
                collections={collections}
                collection={collection}
                onGetUserCollections={getUserCollections}
                onGetUserCollection={getUserCollection}
                onDeleteCollection={deleteCollection}
                manifests={manifests}
                onReceiveManifest={receiveResource}
                onGetManifests={getManifests}
                onDeleteManifest={deleteManifest}
                formatBytes={formatBytes}
                userData={currentUserData}
                handleOutstandingPayment={handleOutstandingPayment}
                spaceUsers={spaceUsers}
                nodes={spaceNodes}
                onNodeSelected={setSpaceNode}
                onClearSpaceUsers={() => setSpaceUsers([])}
                goToUserHome={goToUserHome}
                hub={hub}
                nodeActions={nodeActions}
                onInvokeNodeAction={invokeNodeAction}
                onDragFileToFolder={onDragFileToFolder}
                onCreateFolder={createUserFolder}
                onAddFileToFolder={addFileToFolder}
                onRenameFileInFolder={renameFileInFolder}
                onPolinateResource={PolinateResource}
                onSendResourceToAddress={SendResourceToAddress}
                onCopyToContents={CopyFileToContents}
                onChangeProjectVisibility={changeProjectVisibility}
                onGetDataLineage={getDataLineage}
                onGetDataProvenance={getDataProvenance}
                onGetResourceContents={getResourceContents}
                resourceContents={resourceContents}
                resourceCharacterAnswers={resourceCharacterAnswers}
                onSetResourceContents={setResourceContents}
                onMergeResourcesTogether={MergeFilesTogether}
                onGetWhatDataIsRequired={getWhatDataIsRequired}
                onCharacterPanel={setViewCharacterPanel}
                onShareProject={shareProjectWith}
                onUnshareProject={unshareProjectWith}
              />
              {viewCharacterPanel && (
                <>
                  <BottomScrollingPanel
                    characters={nodeCharacters}
                    onSelectCharacter={onSelectCharacter}
                  />
                </>
              )}
            </>

            {/* <div className="users">
              <Users
                address={address}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                users={users}
                userContents={userContents}
                onGetUsers={getUsers}
                onSearchUsers={searchUsers}
                onGetUserContents={getUserContents}
                onGetUserCollections={getUserCollections}
                onGetUserCollection={getUserCollection}
                onGetManifests={getManifests}
                manifests={manifests}
                collections={collections}
                collection={collection}
                formatBytes={formatBytes}
                onReceiveResource={receiveResource}
                assetName={assetName}
                hash={hash}
              />
            </div> */}

            {/* <ActionMarketplace nodeActions={nodeActions} /> */}

            <Modal
              isOpen={mainMarkdown !== null}
              showClose={true}
              onClose={() => setMainMarkdown(null)}
            >
              <div style={{ textAlign: "justify" }}>
                <Markdown
                //urlTransform={(uri: string) => transformUri(uri)}
                //remarkPlugins={[remarkGfm, remarkMath, remarkMermaid]}
                // children={mainMarkdown}
                // rehypePlugins={[rehypeMermaid]}
                //remarkPlugins={[remarkMermaid]}
                >
                  {mainMarkdown}
                </Markdown>
                {/* <MarkmapView value={mainMarkdown} /> */}
              </div>
            </Modal>

            {/* Download modal */}
            <Modal
              isOpen={isDownloadOpen}
              showClose={downloadPercentage === 100}
              onClose={() => setIsDownloadOpen(false)}
            >
              <h2>Download in progress</h2>
              <div>
                <strong>{paymentFilename}</strong>
              </div>

              <ProgressBar progress={downloadPercentage} />
              <div>
                {formatBytes(downloadedSize)}/{formatBytes(paymentSize)}
              </div>

              <div>Elapsed: {downloadTime / 1000}s</div>
              <div className="stream">{streamStatus}</div>
            </Modal>
            {/* Payment modal */}
            <Modal
              isOpen={isPaymentOpen}
              showClose={true}
              onClose={() => setIsPaymentOpen(false)}
            >
              {beneficiary !== undefined && paymentFilename === "Space" ? (
                <>
                  <h2>Payment requested</h2>
                  <div>
                    Reserve <strong>{paymentFilename}</strong>
                  </div>
                  <div>
                    Size: <strong>{formatBytes(paymentSize)}</strong>
                  </div>
                </>
              ) : (
                <>
                  {beneficiary !== undefined &&
                  beneficiary !== "0x0000000000000000000000000001" ? (
                    <div>
                      <h2>Storage</h2>
                      Consumed: <strong>
                        {formatBytes(storageConsumed)}
                      </strong>{" "}
                      <br />
                      Beneficiary: <strong>{beneficiary}</strong>
                    </div>
                  ) : (
                    <></>
                  )}
                  <h2>Payment requested</h2>
                  {dataowner !== "0x0000000000000000000000000001" ? (
                    <div>
                      Dataowner: <strong>{dataowner}</strong>
                    </div>
                  ) : (
                    <div>No dataowner</div>
                  )}

                  <div>
                    File: <strong>{paymentFilename}</strong>
                  </div>
                  <div>
                    Size: <strong>{formatBytes(paymentSize)}</strong>
                  </div>
                  {/* <div>{paymentResource}</div> */}
                  <div>
                    Price: <strong>{paymentPrice}</strong>
                    {/* {assetName} */}
                  </div>
                </>
              )}

              {balance < paymentPrice ? (
                <div>
                  <hr />
                  <strong>Not enough balance</strong>
                  <br />
                </div>
              ) : (
                <>
                  <hr />
                  {beneficiary !== undefined && paymentFilename === "Space" ? (
                    <>
                      <button
                        onClick={() =>
                          handlePayForStoragePayment(
                            paymentResource,
                            paymentPrice
                          )
                        }
                      >
                        Pay for space
                      </button>
                      <br />
                      Increase your space
                    </>
                  ) : (
                    <>
                      {beneficiary !== undefined ? (
                        <>
                          <button
                            onClick={() =>
                              handleStoragePayment(
                                paymentResource,
                                paymentPrice
                              )
                            }
                          >
                            Pay for upload
                          </button>
                          <br />
                          Uploaded data will not be available for download
                          without payment.
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() =>
                              handlePayment(paymentResource, paymentPrice)
                            }
                          >
                            Pay for download
                          </button>
                        </>
                      )}
                    </>
                  )}
                  <hr />
                </>
              )}
            </Modal>

            <div className="waiting-for-space-action">
              {spaceActions.map((action, index) => (
                <span key={index}>
                  🤖{action?.name}:{action?.resource?.split("/").pop()}&nbsp;
                </span>
              ))}
              {/* {action?.resource} */}
              {/* {console.log("spaceAction", action)} */}
            </div>

            {/* {spaceAction.name === undefined && (
               <div className="waiting-for-space-action">
                 🤖 {spaceAction?.action?.name} <Spinner />
               </div>
             )} */}
          </>
        )}

        {connected === false && (
          <div style={{ overflowY: "auto", height: "100vh" }}>
            {/* <h2>Store • Share • Monetize</h2> */}
            <ul
              style={{
                lineHeight: "1.3em",
                alignItems: "left",
                paddingLeft: "0px",
              }}
            >
              {/* <li className="badge">• Data analysis</li> */}
              <li className="badge">
                • AI Workflows with task chaining and prompt chaining
                capabilities
              </li>
              <li className="badge">
                • Events, services, workflows scheduling
              </li>
              <li className="badge">
                • Pay-to-download mechanism for data monetization
              </li>
              <li className="badge">
                • Project sharing and collaboration tools
              </li>
              {/* <li className="badge">
                •Public/private project visibility control
              </li> */}
              {/* <li className="badge">• EVM blockchain</li> */}
              <li className="badge">• Swarm decentralized storage support</li>
            </ul>
            <ActionMarketplace nodeActions={[]} />
            {/* nodeActions */}
            <CharactersMarketplace nodeCharacters={nodeCharacters} />
            <GroupsMarketplace nodeActions={nodeActions} />
          </div>
        )}
      </>
      <ToastContainer
        hideProgressBar={false}
        theme="light"
        position="bottom-center"
        stacked
        draggable
      />

      <Footer>
        {serviceInfo && (
          <>
            <h2>Details</h2>
            <>
              Ver: {serviceInfo.version} <br />
              {serviceInfo.maintainance}
              <hr />
              <small>
                <strong>Client Node Service Info</strong>
                <br />
                Node Hub: {nodeHub} <br />
                Node RPC: {nodeRpc} <br />
                Symbol: {nodeCurrencyName} ({nodeSymbol}) ChainId: {nodeChainId}{" "}
                <br />
                Node Block Explorer: {nodeBlockExplorer} <br />
                Node Service: {nodeServiceAddress} <br />
                Node Token: {nodeTokenAddress} <br />
                <strong>Client</strong>
                <br />
                Hub: {network.hub.replace("/dataRelayHub", "")} <br />
                Network: {network.name} ChainId: {network.chainId} <br />
                Service: {network.service} <br />
                Token: {network.token} <br />
                {process.env.NODE_ENV} {process.env.CHAIN} <br />
                <strong>Service</strong>
                <br />
                CPU: {serviceInfo.cpu}% &nbsp;PMM:
                {formatBytes(serviceInfo.processMemory)} VMM:
                {formatBytes(serviceInfo.virtualMemory)}
                T: {serviceInfo.threads}
                {/* H: {serviceInfo.handles}  */}
                &nbsp;UPT: {serviceInfo.userProcessorTime} <br />
                RPC: {serviceInfo.ethService?.rpc} <br />
                Service: {serviceInfo.ethService?.service} <br />
                Token: {serviceInfo.ethService?.token} <br />
                Connections: {serviceInfo.connections} &nbsp;Nodes:{" "}
                {serviceInfo.nodeConnections} <br />
                <strong>Node</strong> <br />
                {serviceInfo.nodeClient?.name} <br />
                NodeId: {serviceInfo.nodeClient?.nodeId} <br />
                Connected: {serviceInfo.nodeClient?.relayUri} <br />
                Beneficiary: {serviceInfo.nodeClient?.address} <br />
              </small>
            </>
          </>
        )}
      </Footer>
    </div>
  );
}

export default App;
